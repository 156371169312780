import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useFormik } from "formik";
import * as yup from "yup";

import API from "../../api/api";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import yupTypeValidator from "../../utils/YupTypeValidator";
import Sha256 from "../../utils/sha256";
import { dateFormater, timestampFormater } from "../../utils/date-formater";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { CellHighlight } from "../../utils/custom-cell";

/**
 * @param {initialProps} props
 */
const CRUDPageGridCustomCommentCashier = (props) => {
  // PROPERTIES
  const { t } = useTranslation();
  const user = parsedUser();

  const [fields, setFields] = useState(props.fields);
  const [records, setRecords] = useState([]);

  const toast = useToast();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode); //I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);

  const [model, setModel] = useState(props.page);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const selectedItemRef = useRef(null);

  // Used to update the records length
  useEffect(() => {
    if (typeof props.recordsLength === "function") {
      props.recordsLength(records.length);
      return () => {
        props.recordsLength(0);
      };
    }
  }, [records]);

  // END PROPERTIES

  //METHODS

  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRecordRemote = async (cleanFilter) => {
    if (props.ParentGUID == null) {
      return;
    }
    // First start loading the model
    setLoading(true);

    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      if (props.ParentField) {
        query += `&${props.ParentField}=` + props.ParentFieldValue;
      }
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }

      let request = await API.getAction(props.getEndpoint, query);
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords([]);
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      // console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields

      //! If grid data stays null, we use the data from the API
      setRecords(recordResult);
    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],
      });
    } finally {
      setLoading(false);
    }
  };

  // THIS METHOD IS USED TO BRING THE DETAILS OF THE SECTION USED
  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `token=${user.Token}&IdUser=${user.IdUser}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      let request = await API.getAction(props.detailsEndpoint, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData;
      fillFormikObject(finalResult);
      //setSelectedItem(null)
      //Fill object with new values
    } catch (error) {
      toast({ type: "error", message: t("Error loading data") });
      return;
    }
  };

  const bindDataModelRemote = async (recordId) => {
    //First load records
    try {
      if (props.fields) {
        setFields(props.fields);
        return;
      }
      // console.log
      //       if (props.ParentGUID == null) {
      //         return;
      //       }
      let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}&IdUser=${user.IdUser}`;

      let request = await API.getAction(props.modelEndpoint, query);

      let recordsResult = null;
      //Check for model
      if (request.data.model) {
        //means came with header
        let firstResult = JSON.parse(request.data.model);
        let secondResult =
          typeof firstResult[0] === "string"
            ? JSON.parse(firstResult[0])
            : firstResult[0];
        recordsResult = secondResult;
      } else {
        //Regular display,
        let resultsSections = request.data[0];
        if (resultsSections === undefined) {
          return;
        }
        let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
        recordsResult = parsedResultsSections;
      }

      let parsedResults = recordsResult;
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult =
        typeof parsedResults.JSONData === "string"
          ? JSON.parse(parsedResults.JSONData)[0].JSONData[0]
          : parsedResults.JSONData[0];

      // console.log("model items", finalResult);

      setFields(finalResult.Fields);

      if (props.id === "specialServices") {
        setFields((prev) => {
          return prev.map((item) => {
            if (item.FieldName === "StartDate") {
              item.min = props.Parent.ArrivalDate;
              // a day before the departure date
              item.max = moment(props.Parent.DepartureDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD");
            } else if (item.FieldName === "EndDate") {
              item.min = props.Parent.ArrivalDate;
              // a day before the departure date
              item.max = moment(props.Parent.DepartureDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD");
            }
            return item;
          });
        });
      }
    } catch (error) {
      console.error(error);
      toast({ type: "error", message: t("Error loading data") });
      return;
    }
  };

  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            try {
              let query = `token=${user.Token}&RecordId=${selectedItem.GUID}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
              await API.getAction(props.deleteEndpoint, query);
              await bindDataRecordRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null);
    }
    if (action === "U" || action === "C" || action === "D") {
      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setShowAddEdit(!showAddEdit);
    if (reload) {
      await bindDataRecordRemote();
    }
  };
  const onBlurUpdateField = async (fieldName, fieldValue) => {
    // console.log(fieldName, fieldValue)
    if (props.id === "specialServices" && fieldName === "IdSalesService") {
      updatePriceValue(fieldValue);
    }
  };

  const updatePriceValue = async (value) => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&IdSalesServices=${value}`;

    let request = await API.getAction(
      `api/Reservations/SpecialServicePrice`,
      queryString
    );
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    formik.setFieldValue("Price", parsedResults.JSONData.Price);
    //debugger;
    // console.log(parsedResults)
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    // console.log("fillFormikObject", "recordP", record, "model", model);
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        // console.log(
        //   "field name",
        //   item.FieldName,
        //   "RecordValue ",
        //   record[item.FieldName]
        // );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  // useEffect(() => {
  //   console.log(props.id);
  //   debugger;

  // }, [props.id])
  const prepareFormikObject = () => {
    // console.log("Preparing formik object", model)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    if (props.id === "RoomVirtualToRoom") {
      debugger;
      initialObject[props.customField] = props.customFieldValue;
    }
    formik.initialValues = initialObject;
    formik.setValues(initialObject);

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    // console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj)
    let actions = {
      I: "I",
      U: "U",
      D: "I",
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    // confirmAlert({
    //   closeOnClickOutside: false,
    //   message: "Are you sure you want to save this record?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: async () => {
    try {
      let objSPVersion = "";
      fields.forEach((item) => {
        if (item.FieldName === props.ParentField) {
          return;
        }

        if (props.id === "specialRequest") {
          debugger;
        }

        if (props.id === "specialServices") {
        }

        if (
          item.ReadOnly ||
          item.HtmlType === "separator" ||
          item.FieldName === "IdCustomer" ||
          item.FieldName === "UserNameAdd" ||
          item.Visible === false ||
          item.FieldName === "IdContract"
        )
          return;

        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else if (item.HtmlType === "password") {
          let passwordEncrypted = null;
          if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
            passwordEncrypted =
              obj["UserName"].toUpperCase() + obj[item.FieldName];
            passwordEncrypted = Sha256.hash(passwordEncrypted);
          }
          objSPVersion +=
            obj[item.FieldName] === undefined
              ? "null"
              : "'" + passwordEncrypted + "'";
        } else if (item.HtmlType === "radio") {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0
                ? item.DefaultValue
                : "null"
              : "'" + obj[item.FieldName] + "'";
        } else {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? "null"
              : "'" + obj[item.FieldName] + "'";
        }
      });
      if (actionMode === "U") {
        // console.log("selected item", selectedItem)
        if (!objSPVersion.includes("@GUID")) {
          objSPVersion += `, @GUID='${selectedItem.GUID}'`;
        }
      }
      if (props.ParentField) {
        // console.log(props)
        if (props.ParentFieldType === "int") {
          objSPVersion += `, @${props.ParentField}=${props.ParentFieldValue}`;
        } else {
          objSPVersion += `, @${props.ParentField}='${props.ParentFieldValue}'`;
        }
      }
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}&ParentGUID=${props.ParentGUID}`;

      let queryData = {
        Data: objSPVersion,
      };

      let request = await API.postAction(
        props.addEditEndpoint + "?" + queryString,
        queryData
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: t("Record saved successfully"),
      });

      toggleOpen(null, true);
    } catch (error) {
      console.error(error);
    }
  };

  const [tableHeader, setTableHeader] = useState([]);

  const bindDataHeaderRemote = async (cleanFilter) => {
    try {
      // console.log(user)
      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}`;
      let requestSections = await API.getAction(
        props.headerEndpoint,
        querySections
      );
      // console.log("Header", requestSections)
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      // console.log("Header 2", parsedResultsSections)
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;
      // console.log(recordResultSection);
      //setFormSize(recordResultSection[0].FormSize ?? 'md');

      //! If grid data stays null, we use the data from the API

      let titles = [
        "Audit Information",
        "Added by",
        "Date Added",
        "Modified by",
        "Last Modified",
      ];
      // console.log(recordResultSection)

      setTableHeader(recordResultSection[0].Fields);
    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  // END METHODS

  // UI METHODS
  const loadGRIDUI = () => {
    return (
      <Container id="tab-grid-body" fluid className=" ">
        <Card>
          <Card.Body className="p-2">
            <Row className="mx-0 py-2">
              <Col className="table-responsive">
                <table className=" table table-bordered table-striped">
                  <thead>
                    <tr>
                      {tableHeader &&
                        tableHeader.map((item, index) => {
                          if (item.FieldName === "CommentType") {
                            item.FieldTitle = "Type"
                          }
                          // console.log(" TableHeader Comments Cashier",item)
                          if (item.FieldName === "DateAdd") return null;
                          return (
                            <th
                              key={index}
                              className={`fw-bold ${
                                index === 0 &&
                                item.FieldType === "int" &&
                                "text-center"
                              } text-nowrap`}
                            >
                              {t(item.FieldTitle) ?? t(item.FieldName)}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {records && records.length === 0 ? (
                      <tr>
                        <td
                          colSpan={tableHeader && tableHeader.length}
                          className="text-center py-4"
                        >
                          {t("No data")}
                        </td>
                      </tr>
                    ) : null}
                    {records &&
                      Array.isArray(records) &&
                      records
                      // .filter((x) => x.DateDel === null && props.showdeleted === false)
                      .map((item, index) => {
                        console.log(`CommentsSectionItem index: ${index}`, item);
                        if (item.DateDel !== null && props.showdeleted === true) return null;
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedItem(item);
                            }}
                            className={`${
                              selectedItem && selectedItem.GUID === item.GUID
                                ? "active"
                                : ""
                            } ${item.DateDel ? "scratch" : ""}`}
                          >
                            {tableHeader &&
                              tableHeader.map((column, index2) => {
                                // console.log("🚀 ~ tableHeader.map ~ column:", column)
                                if (column.FieldName === "DateAdd") return null;
                                if (column.FieldName === "CommentType") {
                                  // console.log(
                                  //   "🚀 ~ {tableHeader&&tableHeader.map Comment Type ~ column:",
                                  //   column
                                  // );
                                  return (
                                    <td key={index2} className={"text-nowrap"}>
                                      <span className={'w-100 badge'}style={CellHighlight(item.bgColor, item.txtColor)}>
                                        {item[column.FieldName]}
                                      </span>
                                    </td>
                                    // <td key={index2} className={"text-nowrap"}>
                                    //   {dateFormater(item[column.FieldName])}
                                    // </td>
                                  );
                                }
                                // if (column.FieldType === "date") {
                                //   // console.log(
                                //   //   "🚀 ~ {tableHeader&&tableHeader.map ~ column:",
                                //   //   column
                                //   // );
                                //   return (
                                //     <td key={index2} className={"text-nowrap"}>
                                //       {dateFormater(item[column.FieldName])}
                                //     </td>
                                //   );
                                // }
                                if (column.FieldName === "Comments") {
                                  // console.log("From Comments Field", item[column.FieldName]);
                                  // console.log("From Comments Field I'm trying to get the date", item.DateAdd);

                                  return (
                                    <td key={index2} className={"text-wrap"}>
                                      <small className="text-muted">{timestampFormater(item.DateAdd)}</small>
                                      <br />
                                      {item[column.FieldName]}
                                    </td>
                                  );
                                }
                                return (
                                  <td
                                    key={index2}
                                    className={`${
                                      index2 === 0 &&
                                      item.FieldType === "int" &&
                                      "text-center"
                                    }`}
                                  >
                                    {typeof item[column.FieldName] ===
                                    "boolean" ? (
                                      <input
                                        type="checkbox"
                                        checked={item[column.FieldName]}
                                        className=""
                                      />
                                    ) : (
                                      t(item[column.FieldName])
                                    )}
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
              <Col xs={"auto"} className=" gap-2 pt-2">
                {/* {console.log(selectedItem)} */}
                <button
                  className={`btn btn-primary btn-sm ${
                    selectedItem === "null" && "disabled"
                  }`}
                  onClick={() => {
                    toggleOpen("I");
                  }}
                  type="button"
                  disabled={props.disableAdd}
                >
                  <i className="fas fa-plus"></i>
                </button>
                {selectedItem && (
                  <>
                    <br />
                    <button
                      className={`btn btn-sm text-danger `}
                      onClick={() => deleteConfirm(selectedItem)}
                      type="button"
                      disabled={props.disableEdit}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  };

  //EVENTS
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      selectedItemRef.current = e.component;
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    bindDataHeaderRemote();
    bindDataModelRemote();
    bindDataRecordRemote();
  }, [props.ParentGUID]);

  useEffect(() => {
    if (props.fields) {
      // console.log('PRops Fields Change', props.fields);
      setFields(props.fields);
      prepareFormikObject();
    }
  }, [props.fields]);

  useEffect(() => {
    // console.log('Fields Change', props.fields);
    prepareFormikObject();
  }, [fields]);

  // END EVENTS

  if (props.active === false) return <></>;

  return (
    <>
      {/* DISPLAY THE TYPE OF FORM */}
      <Modal
        show={showAddEdit}
        onHide={() => toggleOpen(null)}
        backdrop="static"
        keyboard={false}
        size={props.modalSize ?? "md"}
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(model.PageTitle)}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Container fluid className="overflow-auto">
              <Row className="gy-5 mb-4 mt-2">
                {fields &&
                  fields
                    .filter(
                      (x) =>
                        x.FieldTitle !== "Audit Information" &&
                        x.FieldName !== "Id" &&
                        x.FieldName !== "IdCustomer" &&
                        x.FieldName !== "DateAdd" &&
                        x.FieldName !== "File" &&
                        x.FieldName !== "DateMod"
                    )
                    .map((item) => {
                      return (
                        <Col
                          className={
                            item.cssClasss + (!item.Visible ? " d-none" : "")
                          }
                          key={`col-${item.FieldName}`}
                        >
                          <label className="me-2 mt-2">
                            {t(item.FieldTitle)}
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                          </label>
                          <br />

                          <FieldSelector
                            actionMode={actionMode}
                            model={item}
                            onBlur={onBlurUpdateField}
                            obj={formik.values}
                            key={`field-${item.FieldName}`}
                            updateField={updateField}
                            value={formik.values[item.FieldName]}
                          ></FieldSelector>
                          {formik.errors[item.FieldName] ? (
                            <div className="invalid text-sm my-1">
                              {t(formik.errors[item.FieldName])}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
              </Row>

              {generalError ? (
                <Row>
                  <Col xs={12}>
                    <div className="alert alert-danger" role="alert">
                      {t(generalError)}
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gx-3 align-items-center justify-content-end">
              {Object.values(formik.errors).length > 0 && (
                <span className="invalid me-2">
                  {t("Please check the forms for errors")}
                </span>
              )}
              <button
                className="btn me-2"
                type="button"
                onClick={() => toggleOpen(null)}
              >
                {t("Close")}
              </button>
              <button
                className="btn btn-primary me-2"
                type="button"
                onClick={() => [formik.submitForm()]}
              >
                {t("Save")}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading ? (
        <React.Fragment>
          <div className="">
            <div className="w-25 mx-auto my-1 p-5 text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">{t("Loading")}...</span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>{loadGRIDUI()}</>
      )}
    </>
  );
};

export default CRUDPageGridCustomCommentCashier;
