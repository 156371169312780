import { Routes, Route, Navigate } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import useIdleTimeout from "./hooks/useIdelTimeOut";
import { useToast } from "./contexts/toast";

export default function Content() {
  const toast = useToast();

  const handleOnIdle = () => {
    toast({
      type: "error",
      message: 'Your Session is about expire',
      width: 'auto',
      height: 'auto'
    })
  }

  const { idleTimer } = useIdleTimeout({ onIdle: handleOnIdle, idleTime: 900 });

  return (
    <>
      <SideNavBarLayout title={appInfo.title}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </SideNavBarLayout>
      <Footer />
    </>
  );
}
