import { Dropdown } from "react-bootstrap";

const getButtonsUI = (type, buttonList, IdAppMenu, selectedItem) => {
  const MenuPermissions = localStorage.getItem("MenuPermissions");
  const CurrentIdAppMenu = IdAppMenu;

  const PermissionSet = JSON.parse(MenuPermissions);
  const filteredPermissions = PermissionSet.filter(
    (x) => x.IdAppMenu === CurrentIdAppMenu
  );

  // PERMISSIONS FILTERING
  const btns =
    filteredPermissions && filteredPermissions.length > 0
      ? buttonList.filter((x) =>
          filteredPermissions.find((y) => y.PermissionCode === x.id)
        )
      : buttonList;

  return (
    <>
      {btns &&
        btns
          .filter((x) => x.type === type)
          .map((btn, index) => {
            if (btn.requireItemSelected && !selectedItem) {
              return null;
            }
            if (type === "button") {
              return (
                <button
                  key={index}
                  id={btn.id}
                  className={btn.cssClass}
                  title={btn.label}
                  disabled={btn.isDisabled}
                  onClick={btn.onClick}
                >
                  <i className={btn.icon}></i>
                </button>
              );
            }

            if (type === "menu") {
              // console.log(btn)
              return (
                <Dropdown.Item key={index} onClick={btn.onClick}>
                  {btn.label}
                </Dropdown.Item>
              );
            }
            return null;
          })}
    </>
  );
};
export default getButtonsUI;
