import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Pagination, Row, Table, Toast } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import API from '../../api/api';
import { CustomBreadcrumbs } from '../../components';


import { actionsModel } from '../../enum/actionsModel.enum';
import useFavorite from '../../hooks/useFavorite';
import { parsedUser } from '../../utils/GetCurrentUser';
import { exportToExcel } from '../../utils/export-to-excel';
import { exportToPDF as GeneratePDF } from '../../utils/export-to-pdf';
import getButtonsUI from '../../shared/getButtonsUI'
import { DATAMODEL } from './dataModel';
import ContractForm from '../../components/contracts-version-III/ContractForm/ContractForm';
import ContractFilter from '../../components/contracts-version-III/Filter/ContractFilter';
import moment from 'moment';
import { findCommonElements } from '../../utils/find-common-elements';
import { useToast } from '../../contexts/toast';

const allowedPageSizes = [{ value: 10, text: 10 }, { value: 20, text: 20 }, { value: 50, text: 50 }, { value: 100, text: 100 }, { value: 0, text: "All" }];

const ContractsV3 = () => {

  const user = parsedUser();
  const { pathname } = useLocation();
  const toast = useToast();
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [showData, setShowData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  //  const [data, setData] = useState([])
  const [showForm, setShowForm] = useState(false);
  const [model, setModel] = useState([])

  const [recordCount, setRecordCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [RecordsQuantity, setRecordsQuantity] = useState(10);
  const [tableHeader, setTableHeader] = useState([]);
  const [sortBy, setSortBy] = useState('Id')
  const [sortByOrder, setSortByOrder] = useState('DESC')
  const [objSPVersion, setObjSPVersion] = useState('');//This object will be used to filter the data

  const { UpdateFavorites, checkIfPinned, isPinned } = useFavorite()


  useEffect(() => {

    checkIfPinned();
    bindDataRemote();
    bindDataHeaderRemote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("Pages", Math.ceil(recordCount / RecordsQuantity))
    setTotalPages(Math.ceil(recordCount / RecordsQuantity))
  }, [recordCount, RecordsQuantity])

  useEffect(() => {
    bindDataRemote();
    setSelectedItem(null);
  }, [currentPage])

  useEffect(() => {
    // console.log("entro", new Date())
    bindDataRemote();
  }, [sortBy, sortByOrder, RecordsQuantity])

  //Bring all the records 
  const bindDataRemote = async (filter) => {
    setIsLoading(true);

    try {

      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let quantity = RecordsQuantity === 0 ? recordCount : RecordsQuantity;
      query = query + `&Quantity=${quantity}&pageNumber=${currentPage}`;
      let queryData = {
        Data: filter,
      };
      let request = await API.postAction("api/Contracts/ContractRecords?" + query, queryData ?? "");
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;

      let recordResult = parsedResults;

      if (recordResult === null) {
        setModel({
          ...model,

          data: {
            ...model.data,

            Rows: []
          },
        });

        return;
        // throw new Error("No data found");
      }

      // Migrate to the new model
      let dataFromAPI = recordResult[0] ? (recordResult[0].JSONConfig ? recordResult[0].JSONData : recordResult) : [];
      // console.log("Results from record", recordResult);


      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns = dataFromAPI && dataFromAPI.length > 0 ? Object.keys(dataFromAPI[0]).filter(x => !x.startsWith("Id")) : null;

      setModel({
        ...model,

        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

      await bindDataCountRemote();

      // setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataCountRemote = async (filter) => {
    setIsLoading(true);

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      query += `&SortBy=${sortBy}&SortType=${sortByOrder}`;
      let queryData = {
        Data: objSPVersion,
      };
      let request = await API.postAction("api/Contracts/contractscount?" + query, queryData ?? "");


      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      setRecordCount(parsedResults.JSONData.Count);

    } catch (error) {
      console.error(error);

    } finally {
      setIsLoading(false);

    }

  };

  //Bring all the details for the selected record
  const bindDataDetailsRemote = async (item) => {
    setIsLoading(true);

    try {
      let theItem = item ? item : selectedItem
      console.log("selected item", selectedItem)
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${theItem.GUID}`;
      let request = await API.getAction("api/Contracts/ContractDetails", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }

      let record = DATAMODEL;
      //Now we need to go over the entire result and assign values to the record
      Object.keys(recordResult).forEach(item => {

        record[item] = recordResult[item];
      })
      setSelectedItem(record);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bindDataHeaderRemote = async (cleanFilter) => {


    try {

      //Now let's load sections
      let querySections = `IdUser=${user.IdUser}&token=${user.Token}`;
      let requestSections = await API.getAction("api/Contracts/Header", querySections);
      // console.log("Header", requestSections)
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      // console.log("Header 2", parsedResultsSections)
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']
      // console.log(recordResultSection)

      setTableHeader(recordResultSection[0].Fields)

    } catch (error) {
      console.error(error);
    } finally {
      //  setLoading(false);
    }
  };

  const actionSelected = (action) => {


    // TODO For edit and create, we need to pass the data to the modal.
    // setData([])

    if (action === actionsModel.CREATE) {
      setSelectedItem(null)
      toggleOpen("I")
    };
    setShowForm(action === actionsModel.EDIT || action === actionsModel.CREATE || action === actionsModel.VIEW);
    if (action === actionsModel.VIEW) {
      toggleOpen("C");
      bindDataDetailsRemote();
    }
    if (action === actionsModel.EDIT) {
      toggleOpen("U")
      bindDataDetailsRemote();
    };
    if (action === actionsModel.DELETE) {
      confirmAlert({
        message: 'Are you sure to delete this item?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => console.log('Click Yes')
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });
    }
  }

  const toggleOpen = async (action) => {
    setActionMode(action);
  };

  const manageFav = () => {
    UpdateFavorites({
      icon: 'fa-solid fa-file-lines',
      path: pathname,
      formPath: 'Rates Management / Rates & Customers / Rates',
      title: 'Contracts',
      user
    })
  }

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedItem.GUID}`;
              await API.getAction(`api/contracts/ContractDelete`, query);
              toast({
                type: 'success',
                message: 'Record deleted successfully',
                width: 'auto'
              })
              await bindDataRemote();
              setSelectedItem(null);
            } catch(error) {
              console.error(error);
              toast({
                type: 'error',
                message: 'An error occurred while deleting the record',
                width: 'auto'
              })
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const refreshTable = async () => {
    await bindDataRemote();
    await bindDataHeaderRemote();
  }

  const headerWithoutAuditFields = () => {
    return tableHeader && tableHeader
      .filter(x => x.FieldTitle !== "Audit Information"
        && x.FieldName !== "Id"
        && x.FieldName !== "IdUserAdd"
        && x.FieldName !== 'DateAdd'
        && x.FieldName !== 'IdUserMod'
        && x.FieldName !== 'DateMod')
  }

  const exportToPDF = async () => {
    GeneratePDF(document.getElementById("contentContainer"));
  }

  const clearSelection = () => {
    setSelectedItem(null);
    setActionMode(null);
  }

  const handleFilter = async (obj) => {
    // console.log("filter", obj)
    setObjSPVersion(obj);
    await bindDataRemote(obj);
    setShowData(true);
  }
  const [buttons, setButtons] = useState([
    {
      id: "btnAdd",
      label: "Add",
      icon: "fa fa-plus",
      isDisabled: isLoading,
      onClick: () => actionSelected(actionsModel.CREATE, {}),
      type: "button",
      cssClass: "btn btn-primary btn-sm my-1 me-2",
      requireItemSelected: false
    },
    {
      id: "btnSearch",
      label: "Search",
      icon: "fa fa-search",
      isDisabled: isLoading,
      onClick: () => setIsSearching(prev => !prev),
      type: "button",
      cssClass: "btn btn-sm my-1 me-2",
      requireItemSelected: false
    },
    {
      id: "btnEdit",
      label: "Update",
      icon: "fa fa-pencil",
      isDisabled: isLoading,
      onClick: () => actionSelected(actionsModel.EDIT, model.data),
      type: "button",
      cssClass: "btn btn-sm my-1 me-2",
      requireItemSelected: true
    },
    {
      id: "btnView",
      label: "View",
      icon: "fa fa-eye",
      isDisabled: isLoading,
      onClick: () => actionSelected(actionsModel.VIEW, model.data),
      type: "button",
      cssClass: "btn btn-sm my-1 me-2",
      requireItemSelected: true
    },
    // MENU
    {
      id: "btnFavorite",
      label: (isPinned ? 'Remove from' : 'Add to') + " favorites",
      icon: null,
      isDisabled: isLoading,
      onClick: () => manageFav(),
      type: "menu",
      cssClass: "",
      requireItemSelected: false
    },
    {
      id: "btnExcel",
      label: "Export to excel",
      icon: null,
      isDisabled: isLoading,
      onClick: () => exportToExcel(model.data, 'Contracts V3'),
      type: "menu",
      cssClass: "",
      requireItemSelected: false
    },
    {
      id: "btnPdf",
      label: "Export to PDF",
      icon: null,
      isDisabled: isLoading,
      onClick: () => exportToPDF(),
      type: "menu",
      cssClass: "",
      requireItemSelected: false
    },
    // {
    //   id: "btnDelete",
    //   label: "Delete",
    //   icon: null,
    //   isDisabled: isLoading,
    //   onClick: () => deleteConfirm(),
    //   type: "menu",
    //   cssClass: "",
    //   requireItemSelected: true
    // },
  ])



  return (
    <React.Fragment>
      <Container>
        <CustomBreadcrumbs pathTitle={'Rates Management / Rates & Customers /Rates'} />
        <div className="row mx-0" id="contentContainer">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center my-1">
                <i className="fa-solid fa-file-lines me-3 fa-2x"></i>
                <div className="d-flex flex-row">
                  <h5 className="my-1">Rates</h5>
                </div>
              </div>
              {isLoading ? (
                <div className="w-25">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>) : null}

              <div className="d-flex">
                {actionMode === null ? (
                  getButtonsUI("button", buttons, localStorage.getItem("CurrentIdAppMenu") * 1, selectedItem)
                ) : null}
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {getButtonsUI("menu", buttons, localStorage.getItem("CurrentIdAppMenu") * 1, selectedItem)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {showForm ? <ContractForm actionMode={actionMode} data={selectedItem} setShow={setShowForm} clearSelection={clearSelection} refreshTable={refreshTable}

              setSelectedItem={setSelectedItem}
            /> :

              <>
                {isSearching && <ContractFilter handleClose={() => setIsSearching(false)} handleFilter={(obj) => handleFilter(obj)} />}
                {showData && <Container >
                  <Row className="mx-2">
                    <div className='table-responsive'>
                      <Table hover size="xl" bordered className="border shadow-sm">
                        <thead className="table-secondary">
                          <tr>
                            {headerWithoutAuditFields()
                              .map((item, index) => {
                                return (
                                  <th key={index} className={`fw-bold clickable`}
                                    onClick={() => {
                                      console.log(item, sortBy, sortByOrder)
                                      setSortBy(item.FieldName);
                                      if (item.FieldName === sortBy) {
                                        if (sortByOrder === 'ASC') {
                                          setSortByOrder('DESC')
                                        } else {
                                          setSortByOrder('ASC')
                                        }
                                      } else {
                                        setSortByOrder('ASC')
                                      }
                                    }}
                                  >{item.FieldTitle} {sortBy === item.FieldName && sortByOrder === 'ASC' ? <i className="fas fa-sort-up"></i> : sortBy === item.FieldName && sortByOrder === 'DESC' ? <i className="fas fa-sort-down"></i> : null} </th>
                                )
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {model?.data && model.data.Rows.map((item, index) => {

                            // console.log("item", item, "header", tableHeader);
                            return (
                              <tr key={index} onClick={() => { setSelectedItem(item) }} className={selectedItem && selectedItem.GUID === item.GUID ? "active" : ""}>
                                {headerWithoutAuditFields()
                                  .map((column, index2) => {
                                    // console.log(column)
                                    if (column.FieldType === "datetime") {
                                      return (
                                        <td key={index2} className={` `}>{moment(item[column.FieldName]).format("MM/DD/YYYY")}</td>
                                      )
                                    }
                                    return (
                                      <td key={index2} className={` `}>{
                                        (typeof item[column.FieldName] === 'boolean') ? <input type="checkbox" checked={item[column.FieldName]} className="" /> : item[column.FieldName]
                                      }</td>
                                    )
                                  })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {(model?.data?.Columns && model?.data?.Rows.length > 0) && (<div className="d-flex align-items-center gap-2">
                        <p className="m-0">Records per page</p>
                        <div>
                          <select
                            className="form-control form-control-sm"
                            value={allowedPageSizes.find(item => item.value === RecordsQuantity)}
                            onChange={(e) => {
                              if (e.target.value < 1) {
                                setRecordsQuantity(recordCount);
                              } else {
                                setRecordsQuantity(e.target.value)
                              }
                              setCurrentPage(1)
                            }}
                          >
                            {allowedPageSizes.map((item, index) => {
                              // console.log(item);
                              return (
                                <option key={index} value={item.value}>
                                  {item.text}
                                </option>
                              );
                            }
                            )}
                          </select>
                        </div>
                      </div>)}
                      {(model?.data?.Columns && model?.data?.Rows.length > 0) && (<Pagination className="my-0">
                        <Pagination.First disabled={isLoading} onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1 || isLoading} />
                        <Pagination.Item disabled={isLoading} active>{currentPage}</Pagination.Item>
                        {(currentPage + 1 > totalPages) ? null : <Pagination.Item disabled={isLoading} onClick={() => setCurrentPage(currentPage + 1)}>{currentPage + 1}</Pagination.Item>}
                        {(currentPage + 2 >= totalPages) ? null : <Pagination.Item disabled={isLoading} onClick={() => setCurrentPage(currentPage + 2)}>{currentPage + 2}</Pagination.Item>}
                        <Pagination.Next disabled={currentPage >= totalPages || isLoading} onClick={() => setCurrentPage(currentPage + 1)} />
                        <Pagination.Last disabled={isLoading} onClick={() => setCurrentPage(totalPages)} />
                      </Pagination>)}
                    </div>
                  </Row>
                </Container>}
              </>
            }

          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default ContractsV3
