import { t } from "i18next";

const RESERVATION_RESTRICTION_FIELDS = [
  {
    FieldName: "Id",
    FieldType: "bigint",
    FieldTitle: t("Id"),
    cssClasss: "col-12 col-md-3",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: false,
    ReadOnly: true,
  },
  {
    FieldName: "Description",
    FieldType: "varchar",
    FieldTitle: t("Description"),
    cssClasss: "col-12 col-md-11",
    HtmlType: "text",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "StartDate",
    FieldType: "date",
    FieldTitle: t("Start Date"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    maxValueDependOf: "EndDate",
  },
  {
    FieldName: "EndDate",
    FieldType: "date",
    FieldTitle: t("End Date"),
    cssClasss: "col-12 col-md-4",
    HtmlType: "date",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
    minValueDependOf: "StartDate",
  },
  {
    FieldName: "Nights",
    FieldType: "varchar",
    FieldTitle: t("Nights"),
    cssClasss: "col-12 col-md-3",
    HtmlType: "number",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "RestrictionType",
    FieldType: "char",
    FieldTitle: t("Restriction Type"),
    cssClasss: "col-12 col-md-5",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "A",
    OptionValues: `[{"id": "A","text": "Arrival"},{"id": "P","text": "Pass Through"}]`,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "RestrictionScope",
    FieldType: "bit",
    FieldTitle: t("Restriction Scope"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "A",
    OptionValues: `[{"id": "A","text": "All"},{"id": "M","text": "Market Segment"},{"id": "R","text": "Special Rate"}]`,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "IdMarketSegment",
    FieldType: "bigint",
    FieldTitle: t("Market Segment"),
    cssClasss: "col-12 col-md-11",
    HtmlType: "select",
    PlaceHolder: "",
    DefaultValue: "",
    OptionValues: null,
    DataSource:'{"HashID":"295e14da5b9cdb270caead127ecc4aa64b0e70d32b8e161908da154aa3ae7968"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Active",
    FieldType: "bit",
    FieldTitle: t("Active"),
    cssClasss: "col-12 col-md-6",
    HtmlType: "radio",
    PlaceHolder: "",
    DefaultValue: "1",
    OptionValues: `[{"id": "1","text": "Yes"},{"id": "0","text": "No"}]`,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export default RESERVATION_RESTRICTION_FIELDS;
