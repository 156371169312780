import React, { useEffect, useState } from "react";
import API from "../../api/api";
import LogFileDetails from "./LogFileDetail/LogFileDetails";
import CustomBreadcrumbs from "../breadcrumb";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { CleanExtension } from "../../utils/CleanExtencion";

import { useTranslation } from "react-i18next";

const LogFilesContainer = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedLog, setSelectedLog] = useState(null);
  const [logFiles, setLogFiles] = useState([]);

  const [showLogFile, setShowLogFile] = useState(false);

  const RequestLogFiles = async () => {
    try {
      let requestAPI = await API.getAction("/api/TestSP/LogFiles");

      if (requestAPI.status === 200 && requestAPI.data.length > 0) {
        setLogFiles(requestAPI.data);
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseLogFile = () => setShowLogFile(!showLogFile);

  useEffect(() => {
    RequestLogFiles();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modalRight"
        dialogClassName="modal-90w"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center my-1">
              <i className="dx-icon fa-solid fa-file me-3 fa-2x" />
              <div className="d-flex flex-column">
                <h5 className={"my-1"}>{t("Dev Tools")}</h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <button
                className="btn btn-primary mx-2"
                onClick={() => {
                  window.location = "/modelManagement";
                }}
              >
                {t("Model Management")}
              </button>
            </Col>
            <Col>
              <button
                className="btn btn-primary mx-2"
                onClick={() => {
                  window.location = "/process/assignButtons";
                }}
              >
                {t("Assign Buttons")}
              </button>
            </Col>
          </Row>
          {showLogFile ? (
            <LogFileDetails
              show={showLogFile}
              handleClose={handleCloseLogFile}
              selectedLog={selectedLog}
            />
          ) : null}
          <Container className="mt-0 pt-0">
            <Row>
              <Col>
                <div className=" px-0">
                  <div className="w-100">
                    <div
                      className="wrapper"
                      style={{ overflowY: "auto", maxHeight: "90vh" }}
                    >
                      <table className="table table-bordered shadow-sm table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th className="text-center text-nowrap">
                              {t("Log Name")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logFiles && logFiles.length > 0 ? (
                            logFiles.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={` clickable ${
                                    item === selectedLog ? " active" : ""
                                  }`}
                                  onClick={() => {
                                    handleCloseLogFile();
                                    setSelectedLog(item);
                                  }}
                                >
                                  <td
                                    className="text-center "
                                    style={{ width: "5%" }}
                                  >
                                    <span
                                      className={
                                        item === selectedLog
                                          ? ""
                                          : "text-primary"
                                      }
                                    >
                                      {item === selectedLog && showLogFile
                                        ? t("SELECTED")
                                        : t("EDIT")}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    {CleanExtension(item, ".txt")}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="border-1 text-center" colSpan={12}>
                                {t("No Records Found")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogFilesContainer;
