import { timestampFormater } from "../../../utils/date-formater";
import { USDollar } from "../../../utils/number-to-currency";

export const cashClosingReport = (closingHeader, closingDetails = [], TransactionTotal = [], AmountTotal = []) => {
  const {
    BusinessUnit,
    ClosingDate,
    OpenDate,
    ClosingUserName,
    Comment,
    CashierCashFund,
    CashierCashFundLocal,
    Sequence,
  } = closingHeader;

  const denominationsDOP = closingDetails
  .filter((detail) => detail.ISOCode === 'DOP')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  })

  const denominationUSD = closingDetails
  .filter((detail) => detail.ISOCode === 'USD')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  });
  
  const denominationsEUR = closingDetails
  .filter((detail) => detail.ISOCode === 'EUR')
  .map((detail) => {
      return {
        Quantity: detail.Quantity,
        Denomination: detail.Denomination,
        TotalAmount: detail.TotalAmount,
      }
  });
  
  const TransactionTotal_ = TransactionTotal.map((transaction) => {
    return {
      Denomination: transaction.Denomination,
      TotalAmount: transaction.TotalAmount,
      TotalAmountBase: transaction.TotalAmountBase,
    }
  });

  const AmountTotal_ = AmountTotal.map((amount) => {
    return {
      ISOCode: amount.ISOCode,
      Currency: amount.Cunrency,
      TotalAmount: amount.TotalAmount,
      TotalAmountBase: amount.TotalAmountBase,
      AvgCurrencyRateValue: amount.AvgCurrencyRateValue,
    }
  });

  const TotalTransacciones = TransactionTotal.reduce((acc, curr) => acc + curr.TotalAmountBase, 0);
  const TotalGeneral = AmountTotal.reduce((acc, curr) => acc + curr.TotalAmount, 0 );

  const montoDiferencia = TotalGeneral - TotalTransacciones;
  const mensajeCierre = (montoDiferencia) => {
    if (montoDiferencia > 0) {
      return `Cash Overage ${USDollar(montoDiferencia)}`;
    }
    if (montoDiferencia < 0) {
      return `Cash Shortage ${USDollar(montoDiferencia)}`;
    }
    return "Succesful Cash Closing";
  }

  return {
    header: function (currentPage, pageCount, pageSize) {
      return {
        columns: [
          {
            image: 'snow', width: 175,
            height: 80,
            margin: [20, 20, 0, 20],
          },
          { text: `Page ${currentPage} of ${pageCount}`, alignment: 'right', fontSize: 8, margin: [0, 20, 20, 0] }
        ]
      }
    },
    pageMargins: [40, 80],
    // pageMargins: [40, 80, 40, 75],
    // pageSize: "letter",
    content: [
      {
        text: 'Cash Closing Report',
        bold: true,
        fontSize: 14,
        margin: [0, 20, 0, 20],
        alignment: 'center',
      },
      {
        layout: 'noBorders',
        table: {
          widths: [60, '*', 60, '*'],
          margin: [20, 20],
          body: [
            [
              {
                text: 'Hotel:',
                fontSize: 10,
                bold: true,
              },
              {
                text: BusinessUnit,
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Open Date:',
                fontSize: 10,
                bold: true,
              },
              {
                text: timestampFormater(OpenDate),
                // text: moment(OpenDate).format('MM/DD/YYYY HH:mm:ss'),
                fontSize: 10,
                bold: false,
              },
            ],
            [
              {
                text: 'User:',
                fontSize: 10,
                bold: true,
              },
              {
                text: ClosingUserName,
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Closing Date:',
                fontSize: 10,
                bold: true,
              },
              {
                text: timestampFormater(ClosingDate),
                fontSize: 10,
                bold: false,
              }
            ],
            [
              {
                text: 'Cash Fund USD:',
                fontSize: 10,
                bold: true,
              },
              {
                text: USDollar(CashierCashFund || 0),
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Cash Fund DOP:',
                fontSize: 10,
                bold: true,
              },
              {
                text: USDollar(CashierCashFundLocal || 0),
                fontSize: 10,
                bold: false,
              }
            ],
            [
              {
                text: 'Comments:',
                fontSize: 10,
                bold: true,
              },
              {
                text: Comment,
                fontSize: 10,
                bold: false,
              },
              {
                text: 'Sequence:',
                fontSize: 10,
                bold: true,
              },
              {
                text: Sequence,
                fontSize: 10,
                bold: false,
              }
            ],
          ]
        }
      },
      {
        text: '',
        bold: true,
        fontSize: 8,
        margin: [0, 10],
        alignment: 'center',
      },
      {
        columnGap: 15,
        columns: [
          // First Column
          {
            stack: [
              {
                text: 'Cash Breakdown EUR',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denomination',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Quantity',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationsEUR.map((denomination, index) => {
                      if (index === denominationsEUR.length - 1) {
                        return [
                          {
                            text: "Total EUR",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationsEUR.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
              {
                text: 'Cash Breakdown DOP',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denomination',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Quantity',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationsDOP.map((denomination, index) => {
                      if (index === denominationsDOP.length - 1) {
                        return [
                          {
                            text: "Total DOP",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationsDOP.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
              {
                text:"",
                margin: [0, 15],
              },
              {
                text: "",
                fontSize: 12,
              },
              {
                text: mensajeCierre(montoDiferencia),
                bold: true,
                fontSize: 14,
                margin: [0, 5],
              },
            ],
          },
          // Second Column
          {
            stack: [
              {
                text: 'Cash Breakdown USD',
                bold: true,
                fontSize: 12,
                margin: [0, 10],
              },
              {
                layout: 'lightHorizontalLines',
                table: {
                  widths: ['auto', 'auto', 75],
                  body: [
                    [
                      {
                        text: 'Denomination',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Quantity',
                        bold: true,
                        fontSize: 10,
                        alignment: 'center',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                      {
                        text: 'Total',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        fillColor: '#3A4546',
                        color: '#FFFFFF',
                      },
                    ],
                    ...denominationUSD.map((denomination, index) => {
                      if (index === denominationUSD.length - 1) {
                        return [
                          {
                            text: "Total USD",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: "",
                            fontSize: 10,
                            alignment: 'center',
                          },
                          {
                            text: USDollar(denominationUSD.reduce((acc, curr) => acc + curr.TotalAmount, 0)),
                            fontSize: 10,
                            alignment: 'right',
                          },
                        ]
                      }
                      
                      return [
                      {
                        text: denomination.Denomination,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: denomination.Quantity,
                        fontSize: 10,
                        alignment: 'center',
                      },
                      {
                        text: USDollar(denomination.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]}),
                  ],
                },
              },
              {
                text:"",
                margin: [0, 10],
              },
              {
                text: 'Total Cash Counted',
                bold: true,
                alignment: 'center',
                fontSize: 12,
                margin: [30, 5],
              },
              {
                layout: 'noBorders',
                // widths: [100, 100],
                table: {
                  widths: [85, 65, 60],
                  // widths: [95, 50, 50],
                  body: [
                    [
                      {
                        text: "Payment Concept",
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: "Amount",
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: "USD",
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                      }
                    ],
                    ...AmountTotal_.map((amount) => [
                      {
                        text: amount.Currency,
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: USDollar(amount.TotalAmountBase),
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: USDollar(amount.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]),
                    [
                      {
                        text: "Total General",
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: "",
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: USDollar(TotalGeneral),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]
                  ],
                },
              },
              {
                text:"",
                margin: [0, 5],
              },
              {
                text: 'Transaction Breakdown',
                bold: true,
                fontSize: 12,
                margin: [30, 5],
              },
              {
                layout: 'noBorders',
                // widths: [100, 100],
                table: {
                  widths: [80, 65, 65],
                  body: [
                    [
                      {
                        text: "Payment Concept",
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: "Amount",
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: "USD",
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                      }
                    ],
                    ...TransactionTotal_.map((transaction) => [
                      {
                        text: transaction.Denomination,
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: USDollar(transaction.TotalAmount),
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: USDollar(transaction.TotalAmountBase),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]),
                    [
                      {
                        text: "Total",
                        bold: true,
                        fontSize: 10,
                        alignment: 'left',
                      },
                      {
                        text: "",
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: USDollar(TotalTransacciones),
                        fontSize: 10,
                        alignment: 'right',
                      },
                    ]
                  ],
                },
              },
            ],
          },
        ],
      },
      // {
      //   text:"Diferencia: ",
      //   fontSize: 12,
      // },
      // {
      //   text:"Resultado Cierre: ",
      //   fontSize: 12,
      //   margin: [0, 5],
      // },
    ],
    images: {
      snow: 'https://strapi.globalagents.net/uploads/Lifestyle_Holidays_Hotels_and_Resorts_Logo_9ed9d18972.jpg',
    }
  };
};
