import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import ClearUser from "../utils/ClearUser";
import ROUTES from "../utils/routes";
// import { useAuth } from "../contexts/auth";

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false)
    
    // const {signOut} = useAuth()
    
    const handleIdle = () => {
        setIdle(true)
        // signOut()
        ClearUser();
        window.location = ROUTES.Default.Clean;
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptTimeout: idleTimeout / 6,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500,
    })
    
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;