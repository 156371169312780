import { HighlightOff, Visibility } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";

import { useTranslation } from "react-i18next";
import { parsedUser } from "../../../utils/GetCurrentUser";

const ReportFilter = ({
  filterFields,
  isLoading,
  generateReport,
  clearReport,
}) => {
  const { t } = useTranslation();
  const user = parsedUser();

  const [filters, setFilters] = useState(filterFields);
  const [validationsSchema, setValidationSchema] = useState(null);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: generateReport,
    onReset: clearReport,
  });

  useEffect(() => {
    prepareFormikObject();
  }, [filters]);

  useEffect(() => {
    // console.log(filters)
    // console.log({user})
    const newFilters = filters.map((item) => {
      if (item.FieldType.toLowerCase() === "date") {
        item.value = user?.OperationDate;
      }
      return item;
    }
    );
    setFilters(newFilters);
  }, []);

  const updateField = (fieldName, fieldValue, FieldType, HtmlType) => {
    if (HtmlType === "select" && fieldValue === "") {
      fieldValue = null;
    }
    if (fieldValue === "Invalid date") {
      fieldValue = "";
    }
    if (FieldType === "date") {
      if (fieldValue === "") {
        fieldValue = null;
      }
      if (fieldName === "StartDate") {
        formik.setFieldValue("EndDate", fieldValue);
      }
      if (fieldName === "ArrivalDate1") {
        formik.setFieldValue("ArrivalDate2", fieldValue);
      }
    }

    if (fieldName === "ReservationNumber") {
      fieldValue = fieldValue.replace(/\D/g, "");
    }
    
    formik.setFieldValue(fieldName, fieldValue);
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (filters) {
      filters.forEach((item) => {
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    formik.setValues(initialObject);
    setValidationSchema(yup.object(initialObjectValidation));
  };

  return (
    <Accordion className="mb-4 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0">{t("Filters")}</Accordion.Header>
        <Accordion.Body className="bg-light">
          <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Row>
              {filters &&
                filters.map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={index}
                    >
                      <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
                      {item.Required ? (
                        <i className="required-asterisk ms-1 text-danger">*</i>
                      ) : null}
                      <br />

                      <FieldSelector
                        model={item}
                        key={index}
                        updateField={(fieldName, value) => updateField(fieldName, value, item.FieldType, item.HtmlType)}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}

              <Col className="col-12 d-flex justify-content-end gap-2 mt-3">
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  type="reset"
                  title={t("Clear")}
                  disabled={isLoading}
                >
                  <HighlightOff /> {t("Clear")}
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  title={t("Search")}
                  type="submit"
                  disabled={isLoading}
                >
                  <Visibility /> {t("Preview")}
                </button>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ReportFilter;
