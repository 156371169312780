import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import RoomBlockContainer from '../../components/room-block/RoomBlockContainer';
import { RoomBlockDetails } from '../../components/room-block/RoomBlockDetails';
import { confirmAlert } from 'react-confirm-alert';
import { parsedUser } from '../../utils/GetCurrentUser';
import { useToast } from '../../contexts/toast';
import { exportToExcel as GenerateXLS } from '../../utils/export-to-excel';
import API from '../../api/api';
import { ExcelRecordMapper } from '../../utils/RecordMapperExcel';

const initialModel = {
  section: {},
  data: {
    Columns: [],
    Rows: [],
  },
};

const RoomBlockPage = () => {
  const user = parsedUser();
  const toast = useToast();
  const [actionMode, setActionMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const [records, setRecords] = useState(initialModel);

  const principalButtonList = [
    {
      id: 1,
      title: "Edit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: 2,
      title: "New",
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      onClick: () => toggleAddEdit("I"),
    },
    {
      id: 3,
      title: "Release",
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-key",
      onClick: () => toggleRelease(),
    },
    {
      id: 4,
      title: "Print",
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-print",
      onClick: () => exportToExcel(),
    }
  ];

  const exportToExcel = useCallback(async () => {
    const exceptions = ["Row", "Id", "IdRoom", "IdRoomBlockType", "IdDepartment", "StateBgColor", "StateTxtColor", "RowStatus", "IdUserAdd", "IdUserMod",	"DateAdd",	"DateMod",	"DateDel",	"GUID", "Active"];
    const listRecords = records.data.Rows
      .filter((record) => record.Active)
      .map((record) => ExcelRecordMapper.mapRecord(record, exceptions));
    
      if (!listRecords.length) {
      toast({
        type: "error",
        message: "No records to export",
        width: 'auto',
        height: 'auto'
      })
      return;
    }
    GenerateXLS(listRecords, 'Room Block List');
  }, [records.data.Rows])

  const toggleAddEdit = (action) => {
    if (action === "I") {
      setSelectedItem(null);
      setActionMode(action);
      setShow(!show);
    }
    if (action === 'U') {
      if (!selectedItem) return;
      setActionMode(action);
      setShow(!show);
    }
  };

  const toggleRelease = () => {
    if (!selectedItem) return;
    if (selectedItem.StateDescription.toLowerCase() === 'released') {
      toast({
        type: "error",
        message: "Room block is already released",
        width: 'auto',
        height: 'auto'
      })
      return;
    }
    confirmAlert({
      message: 'Are you sure you want to release this room block?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            releaseRoomBlock(selectedItem.GUID);
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const releaseRoomBlock = async (GUID) => {
    try {
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=R`;
      let queryData = {
        Data: `@GUID='${GUID}'`,
      };
      let request = await API.postAction(
        `api/RoomBlock/AddEdit?${queryString}`,
        queryData
      );
      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          throw new Error(response.Msg);
        }
      }

      toast({
        type: "success",
        "message": "Room block released successfully",
      })
      setReloadData(true);

    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
          width: 'auto',
          height: 'auto'
        })
        return;
      }
      toast({
        type: "error",
        message: "An error occurred",
        width: 'auto',
        height: 'auto'
      })
    }
  }

  const handleClose = () => {
    setSelectedItem(null);
    setShow(false);
    setActionMode(null);
  }

  return (
    <>
      <Breadcrumbs pathTitle={"Front-Desk / Room Block"} />
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 ">
              {isLoading ? (
                <>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center my-1">
                    <i className="fa-solid fa-chess-rook me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Room Block</h5>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="ps-3 py-2 z-2"
              style={{ position: "sticky", top: 0, backgroundColor: "#f2f2f2" }}
            >
              <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
                {principalButtonList.map((item) => {
                  if (item.ifSelectedItem && selectedItem) {
                    //Validate if checking is pressed
                    if (item.id === "checkInBtn" && !selectedItem.IdRoom) {
                      return <></>;
                    }
                    if (item.id === "assignRoom" && selectedItem.IdRoom) {
                      return <></>;
                    }

                    return <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {item.title}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    
                  } else if (!item.ifSelectedItem) {
                    return (
                      <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {item.title}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    );
                  } else return <></>;
                })}
              </div>
              {/* <div className="d-flex w-100 my-2 justify-content-start gap-1">
              </div> */}
            </div>
            {show && <RoomBlockDetails
              toggle={toggleAddEdit}
              show={show}
              actionMode={actionMode}
              selectedRoom={selectedItem}
              clearSelection={() => setSelectedItem(null)}
              handleClose={handleClose}
            />}
            <RoomBlockContainer
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showAddEdit={show}
              reloadData={reloadData}
              setReloadData={setReloadData} 
              setRecords={setRecords}
            />
          </div>
        </div>
      </Container>
    </>
  )
};

export default RoomBlockPage;
