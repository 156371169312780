export const FilterFieldSet = [
  {
    "FieldName": "IdBusinessUnitGroup",
    "FieldType": "bigint",
    "FieldTitle": "Business Unit Group",
    "HtmlType": "select",
    "cssClasss": "col-md-3",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"1307259d4c6f60eb587cbb3a6ce2f048d6841d1b6e82bb168a0751b81bcf0e0f\"}",
    "ReportCriteria": true
  },
  {
    "FieldName": "IdBusinessUnit",
    "FieldType": "bigint",
    "FieldTitle": "Business Unit",
    "HtmlType": "select",
    "cssClasss": "col-md-3",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef\"}",
    "ReportCriteria": true
  },
  {
    "FieldName": "IdCurrency ",
    "FieldType": "bigint",
    "FieldTitle": "Currency",
    "HtmlType": "select",
    "cssClasss": "col-md-2",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b\"}",
    "ReportCriteria": true
  },
  {
    "FieldName": "IdCustomer",
    "FieldType": "bigint",
    "FieldTitle": "Customer",
    "HtmlType": "select",
    "cssClasss": "col-md-2",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a\"}",
    "ReportCriteria": true
  },
  {
    "FieldName": "IdRoomType",
    "FieldType": "bigint",
    "FieldTitle": "Room Type",
    "HtmlType": "select",
    "cssClasss": "col-md-2",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384\"}",
    "ReportCriteria": true,
    "dependOf": "IdBusinessUnit"
  },
  {
    "FieldName": "IdMealPlan",
    "FieldType": "bigint",
    "FieldTitle": "Meal Plan",
    "HtmlType": "select",
    "cssClasss": "col-md-2",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": "{\"HashID\":\"4bf2a611eaf1b54c557e672a9976de2d180972861db45c396f5a7c95f2819576\"}",
    "ReportCriteria": true,
    "Required": false
  },
  {
    "FieldName": "Date",
    "FieldType": "date",
    "FieldTitle": "Date",
    "HtmlType": "date",
    "cssClasss": "col-md-2",
    "Visible": true,
    "DefaultValue": "",
    "OptionValues": null,
    "DataSource": null,
    "ReportCriteria": true,
    "Required": true
  }
]