import { t } from "i18next";

const CustomerFields = [
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Name",
    FieldType: "bigint",
    FieldTitle: t("Name"),
    Position: 1,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: true,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdCustomerType",
    FieldType: "bigint",
    FieldTitle: t("Customer Type"),
    Position: 2,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountry",
    FieldType: "bigint",
    FieldTitle: t("Country"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdParent",
    FieldType: "bigint",
    FieldTitle: t("Parent"),
    Position: 4,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountryState",
    FieldType: "bigint",
    FieldTitle: t("State"),
    Position: 5,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address1",
    FieldType: "bigint",
    FieldTitle: t("Address 1"),
    Position: 6,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Address2",
    FieldType: "bigint",
    FieldTitle: t("Address 2"),
    Position: 7,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "City",
    FieldType: "bigint",
    FieldTitle: t("City"),
    Position: 8,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "ZipCode",
    FieldType: "bigint",
    FieldTitle: t("Zip Code"),
    Position: 9,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "WebSite",
    FieldType: "bigint",
    FieldTitle: t("Web Site"),
    Position: 10,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
];

export const CustomerFilterFields = [
  {
    FieldName: "Code",
    FieldType: "varchar",
    FieldTitle: "Code",
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    FieldName: "Name",
    FieldType: "varchar",
    FieldTitle: "Name",
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 19,
    Id: 1901003,
    FieldName: "IdCustomerType",
    FieldType: "bigint",
    FieldTitle: t("Customer Type"),
    Position: 2,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"14f0d3cba4adde509b0d6c639da4a737f318e49ccd65fd00aff40aa41de3b686"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCurrency",
    FieldType: "bigint",
    FieldTitle: t("Currency"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCountry",
    FieldType: "bigint",
    FieldTitle: t("Country"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"b5aa6f64a52e815bafe085d4364b48b2c23779dcd503a53781aa04e618a621c9"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdCreditCondition",
    FieldType: "bigint",
    FieldTitle: t("Credit Condition"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"e848539157be735ef46f09776b7c39fb8e6f25d8122d5a341590d737c0551bec"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdMarketSegment",
    FieldType: "bigint",
    FieldTitle: t("Market Segment"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"295e14da5b9cdb270caead127ecc4aa64b0e70d32b8e161908da154aa3ae7968"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdBusinessSource",
    FieldType: "bigint",
    FieldTitle: t("Business Source"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"66c2efa99e79455e6bcf27fec4a44e8309e20fb5ecae0b3dca6bee32f01e54ab"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "IdNCFType",
    FieldType: "bigint",
    FieldTitle: t("NCF Type"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "select",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: '{"HashID":"b0f336a758fe4e570ba9f624cc7b3e6f0c88c76f7c6ecd7b555ac2ad53930c90"}',
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "RNC",
    FieldType: "varchar",
    FieldTitle: t("RNC"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "text",
    PlaceHolder: null,
    DefaultValue: null,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
  {
    IdAppForm: 62,
    Id: 6200010,
    FieldName: "Active",
    FieldType: "bool",
    FieldTitle: t("Active"),
    Position: 3,
    cssClasss: "col-6 col-md-2",
    HtmlType: "checkbox",
    PlaceHolder: null,
    DefaultValue: true,
    OptionValues: null,
    DataSource: null,
    Required: false,
    Visible: true,
    ReadOnly: false,
  },
]

export default CustomerFields;
