/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import ReservationDetailsHeader from "./ReservationDetailsHeader";
import GuestInfo from "./GuestInfo";
import SpecialServicesTab from "./SpecialServices";
import SpecialRequestTab from "./SpecialRequest";
import ReservationLogModal from "../LogInformation";
import RegistrationCardModal from "../RegistrationCardModal";
import TravelingWith from "./TravelingWith";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import AdvancedRoomSelection from "./AdvancedRoomSelection";
import GuestInfoAddEdit from "./GuestInfoAddEdit";
import { useToast } from "../../../contexts/toast";
import { confirmAlert } from "react-confirm-alert";
import ReservationFormModal from "../ReservationFormModal";
import GuestInfoSimpleAddEdit from "./GuestInfoSimpleAddEdit";
import { dateFormater } from "../../../utils/date-formater";
import { RateInfoTab } from "./RateInfoV2";
import { RateInfoFields } from "../fields/RateInfoFields";
import { useFormik } from "formik";
import * as yup from "yup";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import RateDetails from "./RateDetails";
import { GuestGenerator } from "./GuestGenerator";
import ReservationHeadersDetailsInfo from "./ReservationHeadersDetailsInfo";

import { useTranslation } from "react-i18next";
import AlertDialog from "../../alert-dialog/AlertDialog";
import CRUDPageGridCustomCommentCashier from "../../../pages/crud/CRUDPageGridCustomCommentCashier";
import BumpOutTab from "../Bumpout/BumpOutTab";
import moment from "moment";

const ReservationDetails = (props) => {
  const { t } = useTranslation();

  const user = parsedUser();
  const toast = useToast();
  const [activeTab, setActiveTab] = useState("guestInfo");
  const [showAdvangeRoom, setShowAdvangeRoom] = useState(false);
  const [actionMode, setActionMode] = useState(props.actionMode);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [showReservationLog, setShowReservationLog] = useState(false);
  const [showRegistrationCard, setShowRegistrationCard] = useState(false);
  const [showReservationForm, setShowReservationForm] = useState(false);
  const [guestTypes, setGuestTypes] = useState([]);
  const [guests, setGuests] = useState([]);
  const [hasPrimaryGuest, setHasPrimaryGuest] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [guestInfoActionMode, setGuestInfoActionMode] = useState(null);
  const [fields] = useState(RateInfoFields);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [formikInitialObject, setFormikInitialObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showGuestGenerator, setShowGuestGenerator] = useState(false);
  const [hasRestrictions, setHasRestrictions] = useState(true);
  const [categoryCodeSelected, setCategoryCodeSelected] = useState(null);
  const [disableAddEditGuest, setDisableAddEditGuest] = useState(true);

  const checkAddEditGuestRestrictions = () => {
    const reservationCodes = ["C", "T"];
    // ReservationCode: "C" = Confirmed, "T" = Tentative
    // ActionMode: "I" = Insert, "U" = Update, "K" = CheckIn, "O" = CheckOut, "VIEW" = View

    if ((props.actionMode === "U" || actionMode === "U") && reservationCodes.includes(props.selectedReservation?.ReservationCode)) {
      setDisableAddEditGuest(false);
      return;
    }

    if (props.actionMode === "I" || actionMode === "I") {
      setDisableAddEditGuest(false);
      return;
    }

    if (
      props.selectedReservation?.ReservationCode !== "C" ||
      props.selectedReservation?.ReservationCode !== "T" ||
      props.actionMode === "VIEW"
    ) {
      setDisableAddEditGuest(true);
    }

  }

  useEffect(() => {
    checkAddEditGuestRestrictions();
  }, [props.selectedReservation, actionMode, props.actionMode])

  const toggleAddEditGuest = async (action) => {
    setGuestInfoActionMode(action);
    if (action === "I") {
      setSelectedGuest(null);
    }
    setShowGuestAddEdit(!showGuestAddEdit);

    if (showGuestAddEdit) {
      bindGuestForReservation();
      setSelectedGuest(null);
      formik.setFieldValue("TotalStay", null);
    }
    // await bindDataDetailsRemote();
  };

  // useEffect(() => {
  //   if (guests.length > 0) {
  //     rateRef.current?.submit()
  //   }
  // },[guests])

  useEffect(() => {
    const primaryGuest = guests.find((item) => item.PrimaryGuest === true);
    setHasPrimaryGuest(primaryGuest);
  }, [guests]);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    setFormikInitialObject(initialObject);
    // console.log("🚀 ~ prepareFormikObject ~ initialObject:", initialObject);
    setValidationSchema(yup.object(initialObjectValidation));
  };

  useEffect(() => {
    console.log(formikInitialObject);
  }, [formikInitialObject]);

  const fillFormikObject = (record) => {
    // console.log("🚀 ~ fillFormikObject ~ record:", record);
    formik.setValues(record);
    // console.log(formik.values);
  };

  const buttonList = [
    {
      id: 2,
      name: t("Log"),
      icon: "fa-regular fa-rectangle-list",
      variant: "outline-secondary",
      onClick: () => toggleShowReservationLog(),
    },
    {
      id: 3,
      name: t("Form"),
      icon: "fa-regular fa-clipboard",
      variant: "outline-secondary",
      onClick: () => toggleShowReservationForm(),
    },
    {
      id: 4,
      name: t("Registration Card"),
      icon: "fa-regular fa-id-card",
      variant: "outline-secondary",
      onClick: () => toggleShowRegistrationCard(),
    },
    {
      id: "assignRoom",
      name: t("Assign"),
      icon: "fa-solid fa-sign-in",
      variant: "outline-secondary",
      onClick: () => toggleShowAdvangeRoom(),
    },
  ];

  const toggleShowAdvangeRoom = async () => {
    if (showAdvangeRoom) {
      await bindDataDetailsRemote();
    }
    setShowAdvangeRoom(!showAdvangeRoom);
  };
  const toggleShowReservationLog = () => {
    if (!props.selectedReservation) return;
    setShowReservationLog(!showReservationLog);
  };
  const toggleShowRegistrationCard = () => {
    if (!props?.selectedReservation?.GUID) return;
    setShowRegistrationCard(!showRegistrationCard);
  };
  const toggleShowReservationForm = () => {
    if (!props?.selectedReservation?.GUID) return;
    setShowReservationForm(!showReservationForm);
  };
  const toggleShowGuestGenerator = (status) => {
    setShowGuestGenerator(!showGuestGenerator);

    if (status) bindGuestForReservation();
  };

  const saveInitialReservation = async () => {
    // console.log('Saving initial reservation')
    // console.log(guests, guests.length, selectedReservation)
    // setGuests([]);
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=I`;
    let queryData = {
      Data: "@IdBusinessUnit=" + user.IdBusinessUnit,
    };
    let request = await API.postAction(
      `api/Reservations/ReservationAddEdit?${queryString}`,
      queryData
    );
    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData);
      if (response.Error) {
        alert(response.Msg);
        return;
      }
      setSelectedReservation(response[0].JSONData);
      console.log("🚀 ~ saveInitialReservation ~ response[0].JSONData:", response[0].JSONData)

      // console.log("response,", response[0].JSONData)
      setActionMode("U");
    }
    // console.log(request)
  };

  const [showGuestAddEdit, setShowGuestAddEdit] = useState(false);

  const handleSave = async () => {
    // I need to check if TotalStay is 0 or null when Rate Category Type are "M" or "C"
    // console.log("🚀 ~ handleSave ~ categoryCodeSelected:", categoryCodeSelected)
    if (categoryCodeSelected === "M" || categoryCodeSelected === "C") {
      // console.log("🚀 ~ handleSave ~ formik.values.TotalStay", formik.values.TotalStay)
      // if (formik.values.TotalStay === 0 || formik.values.TotalStay === null) {
      //   toast({
      //     type: "error",
      //     message: t("Total stay cannot be 0 or null"),
      //     height: 'auto',
      //     width: 'auto',
      //     duration: 5500,
      //   });fbookingf
      //   return;
      // }
    }

    // I need to check if actionMode is "K" validate that at least on guest is checked in
    if (props.actionMode === "K") {
      let checkedInGuest = guests.filter((x) => x.CheckIn === true);
      if (checkedInGuest.length === 0) {
        toast({
          type: "error",
          message: t("At least one guest must be checked in"),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }
    }

    if (props.actionMode === "O") {
      let checkedOutGuest = guests.filter((x) => x.CheckOut === true);
      if (checkedOutGuest.length === 0) {
        toast({
          type: "error",
          message: t("At least one guest must be checked out"),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }
    }

    // RateInfo Validation from child component
    let FormValidation = {};
    FormValidation = await formik.validateForm();

    if (Object.keys(FormValidation).length > 0) {
      toast({
        type: "error",
        message: t("No rates found"),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      // console.log("🚀 ~ handleSave ~ err:", FormValidation)
      return;
    }

    let actionMode = props.actionMode; // this is a workaround
    if (!hasPrimaryGuest) {
      toast({
        type: "error",
        message: t("You must have a primary guest"),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      return;
    }

    // Recalculate the rate.
    const err = await searchRate();
    if (err?.Error) {
      toast({
        type: "error",
        message: t("No contract found."),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      formik.setFieldValue("TotalStay", null);
      return;
    }

    if (err?.ArrivalDateError) {
      toast({
        type: "error",
        message: t("Arrival date must be equal to or later than the operative date."),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      return;
    }

    if (err?.DepartureDateError) {
      toast({
        type: "error",
        message: t("Departure date must be equal to or later than the operative date."),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      return;
    }


    if (props.actionMode === "I") {
      actionMode = "U";
    }
    let objSPVersion = "";

    //This is hard coded because the object is being used improperly, every change is made to formik object, but here we are requesting only selectedREservation for some od reason
    selectedReservation.BookingNumber = formik.values.BookingNumber;
    selectedReservation.BookingDate = formik.values.BookingDate;


    selectedReservation.IdBillingBusinessUnit =
      formik.values.IdBillingBusinessUnit;

    console.log(selectedReservation);
    // debugger;
    Object.keys(selectedReservation).forEach((item) => {
      // console.log(
      //   "field",
      //   item,
      //   selectedReservation["Id" + item],
      //   selectedReservation[item],
      //   typeof selectedReservation[item]
      // );

      if (item === "IdBillingBusinessUnit") {
        // debugger;
      }
      if (item === "IdSpecialCode" && selectedReservation[item] === "") {
        objSPVersion += (objSPVersion.length > 0 ? ", " : "") + `@${item}=null`;
        return;
      }
      if (
        item === "RowStatus" ||
        item === "IdUserAdd" ||
        item === "DateAdd" ||
        item === "IdUserMod" ||
        item === "DateMod" ||
        item === "Id" ||
        (item && selectedReservation["Id" + item]) ||
        item === "Customer"
      ) {
        return;
      }
      objSPVersion += (objSPVersion.length > 0 ? ", " : "") + `@${item}=`;
      if (
        selectedReservation[item] === null ||
        selectedReservation[item] === undefined
      ) {
        objSPVersion += "null";
      } else if (typeof selectedReservation[item] === "number") {
        objSPVersion +=
          selectedReservation[item] === undefined ||
            selectedReservation[item] === null
            ? null
            : selectedReservation[item];
      } else if (typeof [selectedReservation[item] === "string"]) {
        objSPVersion += "'" + selectedReservation[item] + "'";
      } else {
        objSPVersion += selectedReservation[item];
        //   "null";
      }
    });

    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;

    let queryData = {
      Data: objSPVersion,
    };

    let uri = "api/Reservations/ReservationAddEdit?";
    // debugger;
    let request = await API.postAction(uri + queryString, queryData);

    if (request.status === 200) {
      let response = JSON.parse(request.data[0].JSONData)[0];
      if (response.Error) {
        toast({
          type: "error",
          message: response.Msg,
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
        // confirmAlert({
        //   message: response.Msg,
        //   buttons: [
        //     {
        //       label: "Yes",
        //     },
        //     { label: "No" },
        //   ],
        // });

        //setGeneralError(response.Msg);
        // return;
      }
      // console.log(response.JSONData);
      //	props.setSelectedITem(response.JSONData);
    }
    toast({
      type: "success",
      message: t("Done!"),
      height: 'auto',
      width: 'auto',
      duration: 5500,
    });
    handleClose();
    // props.toggle(null, true)
  };

  // const validateDate = (date) => moment(date, "YYYY-MM-DD", true).isValid();

  // const compareDate = (date1, date2) => moment(date1).isSame(moment(date2));

  const updateReservation = (fieldName, value) => {
    // debugger;
    // console.log(fieldName, value)
    let item = selectedReservation;
    if (!item) {
      return;
    }
    item[fieldName] = value;

    setSelectedReservation(item);
  };
  // console.log("accion mode", props.actionMode);
  const bindGuestForReservation = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}&IdReservation=${props.selectedReservation?.Id ?? selectedReservation?.Id
      }&GUIDReservation=${props.selectedReservation?.GUID ?? selectedReservation?.GUID
      }`;

    query = query + `&Quantity=300&pageNumber=1`; //Hard coded because wont get any record additional
    let prefix = "Reservations";
    // let prefix = idModule ? "CRUDDynamic" : "CRUD";
    let request = await API.getAction(
      `api/${prefix}/ReservationsGuestRecords`,
      query
    );
    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];

    let recordResult = parsedResults.JSONData || [];

    setGuestTypes(parsedResults.JSONGuestTypes || []);
    setGuests(recordResult);
  };

  const deleteConfirmGuest = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to delete this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedGuest.GUID}&ParentGUID=${selectedReservation.GUID}`;
            query = query + `&Quantity=300&pageNumber=1`; //Hard coded because wont get any record additional
            let prefix = "Reservations";
            // let prefix = idModule ? "CRUDDynamic" : "CRUD";
            await API.getAction(`api/${prefix}/ReservationsGuestDelete`, query);
            await bindGuestForReservation();
            formik.setFieldValue("TotalStay", null);
          },
        },
        { label: t("No") },
      ],
    });
  };

  const undoCheckin = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to undo check-in this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedGuest.GUID}`;

            await API.getAction(`api/reservations/GuestUndoCheckin`, query);
            await bindGuestForReservation();
            await bindDataDetailsRemote();
            setSelectedGuest(null);
          },
        },
        { label: t("No") },
      ],
    });
  };
  const undoCheckout = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to undo check-out this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedGuest.GUID}`;

            await API.getAction(`api/reservations/GuestUndoCheckout`, query);
            await bindGuestForReservation();
            await bindDataDetailsRemote();
            setSelectedGuest(null);
          },
        },
        { label: t("No") },
      ],
    });
  };
  const CheckoutGuest = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to check-out this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedGuest.GUID}`;

            await API.getAction(`api/reservations/Checkout`, query);
            await bindGuestForReservation();
            await bindDataDetailsRemote();
            setSelectedGuest(null);
          },
        },
        { label: t("No") },
      ],
    });
  };

  const toggleCheckout = async () => {
    try {

      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      debugger;
      let filter = `@GUIDReservation='${selectedReservation.GUID}'`;
      let queryData = {
        Data: filter,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/reservations/ValidForCheckout?` + query,
        queryData ?? ""
      );

      console.log(request)
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      let finalResult = parsedResults.Record[0]
      console.log(parsedResults)
      if (finalResult.CheckOutNotAllowed === 1) {
        confirmAlert({
          message: t(finalResult.Message),
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                console.log("ok")

              },
            },
          ]
        });

      } else {
        confirmAlert({
          message: t("Are you sure you want to check out?"),
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                let query = `IdUser=${user.IdUser}&token=${user.Token}`;
                debugger;
                let filter = `@GUIDReservation='${selectedReservation.GUID}'`;
                let queryData = {
                  Data: filter,
                };
                // let prefix = idModule ? "CRUDDynamic" : "CRUD";
                let request = await API.postAction(
                  `api/reservations/PerformCheckout?` + query,
                  queryData ?? ""
                );

                console.log("perfom checkout ", request)
              },
            },
            { label: "No" },
          ],
        });

      }
    } catch (err) {
      console.error(err)
    }
    finally {

    }
  }

  const AllCheckout = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to check-out this record?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${selectedReservation.GUID}`;

            await API.getAction(`api/reservations/AllCheckout`, query);
            await bindGuestForReservation();
            setSelectedGuest(null);
          },
        },
        { label: t("No") },
      ],
    });
  };
  const bindDataDetailsRemote = async () => {
    // console.log(`Retrieving information for ${selectedReservation.GUID} reservation...`);
    console.log(
      "🚀 ~ bindDataDetailsRemote ~ selectedReservation:",
      selectedReservation
    );
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${props.selectedReservation.GUID}`;
      let request = await API.getAction(
        "api/Reservations/ReservationDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      // console.log("🚀 ~ bindDataDetailsRemote ~ recordResult:", recordResult);
      if (recordResult === null) {
        throw new Error(t("No data found"));
      }
      // console.log("🚀 ~ bindDataDetailsRemote ~ recordResult:", recordResult)

      setSelectedReservation(recordResult);

      //   let record = DATAMODEL;
      //   //Now we need to go over the entire result and assign values to the record
      //   Object.keys(recordResult).forEach(item => {

      //     record[item] = recordResult[item];
      //   })
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const releaseRoom = async (obj) => {
    console.log(obj, selectedGuest, selectedReservation);
    confirmAlert({
      message: t("Are you sure you want to release the room?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${selectedReservation.GUID}`;

            let prefix = "Reservations";
            await API.getAction(`api/${prefix}/ReleaseRoom`, query);
            await bindDataDetailsRemote();
          },
        },
        { label: t("No") },
      ],
    });
  };

  useEffect(() => {
    if (props.actionMode && props.actionMode === "I") {
      // setSelectedGuest(null);
      //Insert minimal information
      saveInitialReservation();
    }
  }, [props.actionMode]);

  useEffect(() => {
    if (
      props.selectedReservation &&
      (actionMode === "U" ||
        actionMode === "K" ||
        actionMode === "O" ||
        actionMode === "VIEW")
    ) {
      bindDataDetailsRemote();
      bindGuestForReservation();
    }
  }, [props.selectedReservation]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ actionMode:", actionMode)
    // console.log("🚀 ~ useEffect ~ actionMode:", props.actionMode)
    // console.log("🚀 ~ useEffect ~ selectedReservation:", props.selectedReservation)
    // if (props.selectedReservation) {
    // }
  }, [])

  useEffect(() => {
    prepareFormikObject();
    if (props.selectedReservation) {
      fillFormikObject(props.selectedReservation);
      return;
    }
    fillFormikObject(selectedReservation);
  }, []);

  useEffect(() => {
    // console.log("New changes on selectedReservation", selectedReservation);
    if (selectedReservation) {
      fillFormikObject(selectedReservation);
    }
  }, [selectedReservation]);

  const handleClose = () => {
    // console.log('Closing Reservation Details')
    props.handleClose();
    props.clearSelection();
  };

  const [showRateDetails, setShowRateDetails] = useState(false);
  const toggleRateDetails = () => {
    setShowRateDetails(!showRateDetails);
  };

  const searchRate = async () => {
    try {
      let guestsPrincipal = guests.filter((x) => x.PrimaryGuest === true)[0];
      if (!guestsPrincipal) {
        toast({
          type: "error",
          message: t("Principal guest is required"),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        // debugger;
        return;
      }
      const operationalDate = moment(user?.OperationDate);
      
      // Arrival date must be equal to or later than the operative date
      if (
        moment(formik.values.ArrivalDate).isBefore(operationalDate, "day")
      ) {
        formik.setErrors({
          ArrivalDate: t("Arrival date must be equal to or later than the operative date."),
        });
        toast({
          type: "error",
          message: t("Arrival date must be equal to or later than the operative date."),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return {
          ArrivalDateError: true,
        };
      }

      // Departure date must be equal to or later than the operative date
      if (
        moment(formik.values.DepartureDate).isBefore(operationalDate, "day")
      ) {
        formik.setErrors({
          DepartureDate: t("Departure date must be equal to or later than the operative date."),
        });
        toast({
          type: "error",
          message: t("Departure date must be equal to or later than the operative date."),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return {
          DepartureDateError: true,
        };
      }

      // validate that booking date is not greater than arrival date, using moment
      if (
        moment(formik.values.BookingDate).isAfter(formik.values.ArrivalDate)
      ) {
        formik.setErrors({
          BookingDate: t("Booking date cannot be greater than arrival date."),
        });
        toast({
          type: "error",
          message: t("Booking date cannot be greater than arrival date."),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }

      // clear error
      formik.setFieldError("BookingDate", null);

      // Validate that ArrivalDate and DepartureDate are not the same
      if (formik.values.ArrivalDate === formik.values.DepartureDate && formik.values['Nights'] === 1) {
        formik.setErrors({
          DepartureDate: t("Dates cannot be the same."),
        });
        toast({
          type: "error",
          message: t("The minimum stay period is 1 night."),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }

      let forEval = formik.values;
      console.log("🚀 ~ searchRate ~ forEval:", forEval)
      // console.log("forEval...");
      if (
        !forEval.IdRoomType ||
        !forEval.IdCustomer ||
        !forEval.ArrivalDate ||
        !forEval.DepartureDate ||
        !forEval.IdMealPlan ||
        !forEval.BookingDate ||
        !forEval.IdRateCategoryType ||
        (forEval.IdRateCategoryType === 1 && !forEval.Nights)
      ) {
        debugger;
        toast({
          type: "error",
          message: t("Please check fields on Rate Info tab."),
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }

      toast({
        type: "success",
        message: t("Calculating rate") + "...",
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });

      //Update the reservation
      let res = selectedReservation;
      res.IdRoomType = forEval.IdRoomType;
      res.IdCustomer = forEval.IdCustomer;
      res.ArrivalDate = forEval.ArrivalDate;
      res.DepartureDate = forEval.DepartureDate;
      res.Nights = forEval.Nights;
      res.IdRateCategoryType = forEval.IdRateCategoryType;
      res.RateAssigned = forEval.RateAssigned;
      res.IdSpecialCode = forEval.IdSpecialCode;
      res.IdMealPlan = forEval.IdMealPlan;
      res.BookingDate = forEval.BookingDate;
      res.BookingNumber = forEval.BookingNumber;
      res.Child1RateAssigned = forEval.Child1RateAssigned;
      res.Child2RateAssigned = forEval.Child2RateAssigned;
      res.Child3RateAssigned = forEval.Child3RateAssigned;
      res.Child1Quantity = forEval.Child1Quantity;
      res.Child2Quantity = forEval.Child2Quantity;
      res.Child3Quantity = forEval.Child3Quantity;
      setSelectedReservation(res);
      console.log(selectedReservation, formik.values);
      //  debu
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=R`;
      let bookingNumber = forEval.BookingNumber && forEval.BookingNumber.length > 0 ? "'" + forEval.BookingNumber + "'" : null;
      let bookingDate = forEval.BookingDate && forEval.BookingDate.length > 0 ? "'" + forEval.BookingDate + "'" : null;
      let queryData = {
        Data: `@IdBusinessUnit=${formik.values.IdBillingBusinessUnit
          }, @GUIDReservation='${selectedReservation.GUID}', @IdRoomType=${forEval.IdRoomType
          }, @IdCustomer=${forEval.IdCustomer}, @ArrivalDate='${forEval.ArrivalDate
          }', @DepartureDate='${forEval.DepartureDate}', @Nights=${forEval.Nights
          }, @IdRateCategoryType=${forEval.IdRateCategoryType}, @RateAssigned=${forEval.RateAssigned || 0
          },@Child1RateAssigned =${forEval.Child1RateAssigned || 0
          },@Child2RateAssigned =${forEval.Child2RateAssigned || 0
          },@Child3RateAssigned =${forEval.Child3RateAssigned || 0
          }, @IdSpecialCode=${forEval.IdSpecialCode || "null"}, @IdMealPlan=${forEval.IdMealPlan || null
          }, @BookingDate=${bookingDate}, @BookingNumber=${bookingNumber}, @Child1Quantity=${forEval.Child1Quantity || 0
          }, @Child2Quantity=${forEval.Child2Quantity || 0}, @Child3Quantity=${forEval.Child3Quantity || 0
          }`
      };
      // console.log("🚀 ~ searchRate ~ queryData:", queryData)

      setIsLoading(true);
      let request = await API.postAction(
        `api/Reservations/RateSearch?${queryString}`,
        queryData
      );
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      // console.log(parsedResults)

      if (parsedResults.RateError) {
        toast({
          type: "error",
          message: parsedResults.Msg,
          width: parsedResults.Msg.length * 10,
          height: 'auto',
          duration: 5500,
        });
        formik.setFieldError("IdCustomer", t("No contract found."));
        formik.setFieldValue("TotalStay", null);
        return {
          Error: true,
        };
      }
      formik.setFieldError("IdCustomer", null);
      let jsonData = parsedResults?.JSONData[0];

      const checkRestrictions = await CheckReservationRestrictions();
      if (checkRestrictions.Error) {
        return {
          Error: true,
        };
      }

      formik.setFieldValue("DailyRate", jsonData.DailyRate);
      formik.setFieldValue("TotalStay", jsonData.TotalStay);
      return {};
      // console.log(parsedResults, jsonData)
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const CheckReservationRestrictions = async () => {
    console.log("Checking restrictions");
    try {
      toast({
        type: "success",
        message: t("Checking restrictions") + "...",
        height: 'auto',
        width: 'auto',
        duration: 5500,
      })
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      const payload = {
        Data: `@GUIDReservation='${selectedReservation.GUID}'`
      }
      let request = await API.postAction(`api/Restrictions/CheckReservationRestrictions?${queryString}`, payload);
      let results = request.data[0];

      toast({
        type: "success",
        message: t("Checking restrictions") + "...",
      })

      if (results?.Error) {
        confirmAlert({
          message: results.Message,
          buttons: [
            {
              label: t("Ok"),
            },
          ],
        });
        formik.setFieldError("IdCustomer", t("Error checking restrictions"));
        formik.setFieldValue("TotalStay", null);
        return {
          Error: results?.Error
        };
      }

      return {};

    } catch (error) {
      if (error instanceof Error) {
        toast({
          type: "error",
          message: `${error.message}, while checking restrictions.`,
          height: 'auto',
          width: 'auto',
          duration: 5500,
        });
        return;
      }
      toast({
        type: "error",
        message: t("Error checking restrictions"),
        height: 'auto',
        width: 'auto',
        duration: 5500,
      });
      console.error(error);
    }
  }

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: searchRate,
  });

  // const updateCustomerFolios = async (customerId) => {
  //   let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&IdCustomer=${customerId}&GUIDReservation=${selectedReservation.GUID}`;

  //   let request = await API.getAction(
  //     `api/Reservations/UpdateCustomerFolios`,
  //     queryString
  //   );
  //   let results = request.data[0];
  //   let parsedResults = JSON.parse(results.JSONData)[0];

  //   console.log(parsedResults);
  // };

  const setFieldError = (fieldName, message) => {
    formik.setFieldError(fieldName, message);
  };

  // console.log(selectedReservation);
  const onBlurUpdateField = async (fieldName, fieldValue) => {
    if (fieldName === "RateAssigned" && fieldValue > 0) {
      console.log("Trigger Blur Update ===> ", fieldName, fieldValue);
      await formik.submitForm();
      return;
    }
    //Check for customers change
    // if (fieldName === "IdCustomer" && fieldValue) {
    //   updateCustomerFolios(fieldValue);
    // }
  };

  return (
    <>
      {hasRestrictions && <AlertDialog message={t("Error checking restrictions")} primaryButtonText={"Ok"} title={"Error"} />}
      {showRateDetails && (
        <RateDetails
          show={showRateDetails}
          toggle={toggleRateDetails}
          selectedReservation={selectedReservation}
        />
      )}
      {showReservationLog ? (
        <ReservationLogModal
          GUIDReservation={props?.selectedReservation?.GUID}
          show={showReservationLog}
          handleClose={() => setShowReservationLog(false)}
        />
      ) : null}
      {showRegistrationCard ? (
        <RegistrationCardModal
          GUIDReservation={props?.selectedReservation?.GUID}
          show={showRegistrationCard}
          handleClose={() => setShowRegistrationCard(false)}
        />
      ) : null}
      {showReservationForm ? (
        <ReservationFormModal
          GUIDReservation={props?.selectedReservation?.GUID}
          show={showReservationForm}
          handleClose={() => setShowReservationForm(false)}
        />
      ) : null}
      {showAdvangeRoom ? (
        <AdvancedRoomSelection
          show={showAdvangeRoom}
          selectedReservation={props?.selectedReservation}
          toggle={toggleShowAdvangeRoom}
        />
      ) : null}
      {/* Check-in */}
      {showGuestAddEdit && props.actionMode === "K" ? (
        <GuestInfoAddEdit
          show={showGuestAddEdit}
          actionMode={guestInfoActionMode}
          selectedGuest={selectedGuest}
          toggle={toggleAddEditGuest}
          selectedReservation={selectedReservation}
          currentGuestList={guests}
          refreshData={bindDataDetailsRemote}
        />
      ) : null}

      {showGuestAddEdit && props.actionMode !== "K" ? (
        <GuestInfoSimpleAddEdit
          show={showGuestAddEdit}
          actionMode={guestInfoActionMode}
          selectedGuest={selectedGuest}
          toggle={toggleAddEditGuest}
          selectedReservation={selectedReservation}
          currentGuestList={guests}
        />
      ) : null}
      {showGuestGenerator ? (
        <GuestGenerator
          show={showGuestGenerator}
          // actionMode={guestInfoActionMode}
          // selectedGuest={selectedGuest}
          toggle={toggleShowGuestGenerator}
          selectedReservation={selectedReservation}
          currentGuestList={guests}
        />
      ) : null}

      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modalRight z-3"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Row>
            <Col>
              <h4>
                {t("Reservation")} -{" "}
                {props.actionMode === "I"
                  ? t("New")
                  : props.actionMode === "U"
                    ? t("Edit")
                    : props.actionMode === "K"
                      ? t("Check-In")
                      : props.actionMode === "O"
                        ? t("Check-Out")
                        : props.actionMode === "VIEW"
                          ? t("Consulting")
                          : ""}
              </h4>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className={`mx-0 ${props.actionMode === "I" ? "d-none" : ""}`}>
              <Col className="py-0 m-0" md={4}>
                <div className="d-flex justify-content-start gap-3 align-items-baseline">
                  <span className="display-5" style={{ fontSize: "2.3rem" }}>
                    {t("Room No.")} {selectedReservation?.Room}
                  </span>
                  {selectedReservation?.Room &&
                    (props.selectedReservation?.ReservationCode === "C" ||
                      props.selectedReservation?.ReservationCode === "T") ? (
                    <button
                      className={`btn btn-primary ${props.actionMode === "K" && "d-none"}`}
                      type="button"
                      onClick={() => {
                        releaseRoom();
                      }}
                    >
                      {t("Release Room")}
                    </button>
                  ) : null}
                </div>
              </Col>
              <Col md={8}>
                <ReservationHeadersDetailsInfo
                  selectedReservation={selectedReservation}
                />

              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} className={`mx-0 `}>
                <ReservationDetailsHeader
                  selectedReservation={selectedReservation}
                />
              </Col>
              <Col className="bg-white border" xs={12} md={5}>
                <Row className="mx-0">
                  <div className="col-12 col">
                    <div className="d-flex justify-content-end align-items-center mt-2">
                      <div className="w-100">
                        <label
                          className="me-2 mt-2 fw-bold"
                          style={{ fontSize: "1.5rem" }}
                        >
                          {t("Guest")}
                        </label>
                      </div>
                      <button
                        className="btn btn-outline-secondary"
                        type=""
                        onClick={() => toggleShowGuestGenerator(false)}
                        disabled={
                          disableAddEditGuest
                        }
                      >
                        <i className="fa-solid fa-users" />
                      </button>
                    </div>
                    <hr className="separatorLine" />
                  </div>
                  <Col md={11}>
                    <div className="table-responsive">
                      <Table border striped>
                        <thead>
                          <tr>
                            <th className="text-nowrap">{t("Principal")}</th>
                            <th className="text-nowrap">{t("Last Name")}</th>
                            <th className="text-nowrap">{t("First Name")}</th>
                            <th className="text-nowrap">{t("Guest Type")}</th>
                            <th className="text-nowrap">{t("Check-In")}</th>
                            <th className="text-nowrap">{t("Check-Out")}</th>
                            <th className="text-nowrap">{t("Bracelet ID")}</th>
                            <th className="text-nowrap">
                              {t("Bracelet Type")}
                            </th>

                            <th className="text-nowrap">
                              {t("Check-In Date")}
                            </th>

                            <th className="text-nowrap">
                              {t("Check-Out Date")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {guests &&
                            guests.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={`clickable ${selectedGuest?.Id === item.Id
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    setSelectedGuest(item);
                                  }}
                                >
                                  <td className="text-nowrap text-center">
                                    <input
                                      type="checkbox"
                                      checked={item.PrimaryGuest ?? false}
                                    />
                                  </td>
                                  <td className="text-nowrap">
                                    {item.LastName}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.FirstName}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.GuestType}
                                  </td>

                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      checked={item.CheckIn ?? false}
                                    ></input>
                                  </td>
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      checked={item.CheckOut ?? false}
                                    ></input>
                                  </td>
                                  <td className="text-nowrap">
                                    {item.BraceletID ?? ""}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.BraceletType ?? ""}
                                  </td>

                                  <td className="text-nowrap">
                                    {item.CheckInDate
                                      ? dateFormater(item.CheckInDate)
                                      : ""}
                                  </td>

                                  <td className="text-nowrap">
                                    {item.CheckOutDate
                                      ? dateFormater(item.CheckOutDate)
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                        {props.actionMode === "O" ? (
                          <tfoot>
                            <tr>
                              <td colSpan={10} className="text-start">
                                <button
                                  className="btn btn-outline-secondary"
                                  onClick={() => {
                                    AllCheckout();
                                  }}
                                >
                                  {t("All Checkout")}
                                </button>
                              </td>
                            </tr>
                          </tfoot>
                        ) : null}
                      </Table>
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="d-flex flex-column gap-2 align-content-center align-items-baseline">
                      {props.actionMode === "K" ||
                        props.actionMode === "O" ||
                        props.actionMode === "VIEW" ? null : (
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => {
                            toggleAddEditGuest("I");
                          }}
                          disabled={disableAddEditGuest}
                        >
                          <i className="fa-solid fa-plus" />
                        </button>
                      )}
                      {selectedGuest && props.actionMode !== "K" ? (
                        <>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              toggleAddEditGuest("U");
                            }}
                            disabled={disableAddEditGuest}
                          >
                            <i className={"fa-solid fa-pencil"} />
                          </button>
                        </>
                      ) : null}

                      {/* CHECKIN */}
                      {selectedGuest &&
                        guests.length > 0 &&
                        props.actionMode === "K" &&
                        !selectedGuest.CheckIn ? (
                        <>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              toggleAddEditGuest("K");
                            }}
                            name={"Check-In"}
                          >
                            <i className={"fa-solid fa-sign-in"} />
                          </button>
                        </>
                      ) : null}

                      {/* UNDO CHECKIN */}
                      {selectedGuest &&
                        guests.length > 0 &&
                        props.actionMode === "K" &&
                        selectedGuest.CheckIn ? (
                        <>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => {
                              undoCheckin(selectedGuest);
                            }}
                          >
                            <i className={"fa-solid fa-sign-in"} />
                          </button>
                        </>
                      ) : null}

                      {selectedGuest &&
                        guests.length > 0 &&
                        props.actionMode === "K" &&
                        (!selectedGuest.CheckIn || selectedGuest.CheckIn) ? (
                        <>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              toggleAddEditGuest("K");
                            }}
                          >
                            <i className={"fa-solid fa-pencil"} />
                          </button>
                        </>
                      ) : null}

                      {/* CHECKOUT */}
                      {selectedGuest &&
                        guests.length > 0 &&
                        props.actionMode === "O" &&
                        !selectedGuest.CheckOut ? (
                        <>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              CheckoutGuest(selectedGuest);
                            }}
                          >
                            <i className={"fa-solid fa-sign-in"} />
                          </button>
                        </>
                      ) : null}
                      {/* UNDO CHECKOUT */}
                      {selectedGuest &&
                        guests.length > 0 &&
                        props.actionMode === "O" &&
                        selectedGuest.CheckOut ? (
                        <>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => {
                              undoCheckout(selectedGuest);
                            }}
                          >
                            <i className={"fa-solid fa-sign-in"} />
                          </button>
                        </>
                      ) : null}

                      {props.actionMode === "K" ||
                        props.actionMode === "O" ||
                        props.actionMode === "VIEW"
                        ? null
                        : selectedGuest &&
                        guests.length > 0 && (
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                              deleteConfirmGuest();
                            }}
                          >
                            <i className="fa-solid fa-trash" />
                          </button>
                        )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={7} className="reservationTabs">
                <Tabs
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  onSelect={(e) => {
                    console.log("enter guest info", e);
                    setActiveTab(e);
                  }}
                >
                  {/* {selectedGuest ? */}
                  <Tab
                    eventKey="guestInfo"
                    title={t("Guest Info")}
                    className="border py-2"
                  >
                    <GuestInfo
                      selectedGuest={selectedGuest}
                      selectedReservation={selectedReservation}
                    />
                  </Tab>
                  <Tab
                    eventKey="rateInfoV2"
                    title={t("Rate Info")}
                    className="border py-2"
                  >
                    <RateInfoTab
                      guestTypes={guestTypes}
                      guests={guests}
                      errors={formik.errors}
                      touched={formik.touched}
                      updateFields={formik.setFieldValue}
                      values={formik.values}
                      toggleRateDetails={toggleRateDetails}
                      actionMode={props.actionMode}
                      isLoading={isLoading}
                      searchRate={searchRate}
                      onBlurUpdateField={(fieldName, fieldValue) =>
                        onBlurUpdateField(fieldName, fieldValue)
                      }
                      setFieldError={formik.setFieldError}
                      categoryCodeSelected={(val) => {
                        console.log("categoryCodeSelected", val);
                        setCategoryCodeSelected(val)
                      }}
                      selectedReservation={selectedReservation}
                    />
                  </Tab>
                  <Tab
                    eventKey="specialServices"
                    title={t("Special Services")}
                    className="border py-2"
                  >
                    <SpecialServicesTab
                      actionMode={props.actionMode}
                      selectedReservation={selectedReservation}
                    />
                  </Tab>
                  <Tab
                    eventKey="specialRequest"
                    title={t("Special Request")}
                    className="border py-2"
                  >
                    <SpecialRequestTab
                      actionMode={props.actionMode}
                      selectedReservation={selectedReservation}
                      updateReservation={updateReservation}
                    />
                  </Tab>
                  {/* {!window.location
                    .toString()
                    .includes("beta.signumpms.signos-framework.com/") ? ( */}
                  <Tab
                    eventKey="travelingWith"
                    title={t("Traveling With")}
                    className="border py-2"
                  >
                    <TravelingWith
                      selectedReservation={selectedReservation}
                      actionMode={props.actionMode}
                    />
                  </Tab>
                  {/* ) : null} */}
                  <Tab
                    eventKey="comments"
                    title={t("Comments")}
                    className="border py-2"
                  >
                    {selectedReservation ? (
                      <CRUDPageGridCustomCommentCashier
                        getEndpoint="api/Reservations/ReservationsCommentHistoryRecords"
                        modelEndpoint="api/Reservations/ReservationsCommentHistoryModel"
                        addEditEndpoint="api/Reservations/ReservationsCommentHistoryAddEdit"
                        deleteEndpoint="api/Reservations/ReservationsCommentHistoryDelete"
                        detailsEndpoint="api/Reservations/ReservationsCommentHistoryDetails"
                        headerEndpoint="api/Reservations/ReservationsCommentHistoryHeader"
                        ParentGUID={selectedReservation?.GUID}
                        disableEdit={actionMode === "VIEW"}
                        disableAdd={actionMode === "VIEW"}
                        Parent={selectedReservation}
                        ParentField={"GUIDReservation"}
                        ParentFieldValue={selectedReservation?.GUID}
                        ParentFieldType={"string"}
                        page={{
                          PageIndex: 2,
                          PageTitle: t("Comments"),
                          PageIcon: "fa-solid fa-file-lines",
                          MultiRecord: true,
                          Active: true,
                        }}
                      />
                    ) : null}
                  </Tab>
                  {selectedReservation && selectedReservation?.ReservationsStatus?.includes("Bump") ? (
                    <Tab
                      eventKey="Bump Out"
                      title={t("Bump Out")}
                      className="border py-2"
                    >
                      <BumpOutTab
                        GUIDReservation={selectedReservation?.GUID}
                      />
                    </Tab>
                  ) : null}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ maxHeight: "7vh" }} className="py-0">
          <Row className="w-100">
            <Col>
              <div className="btn-group">
                {buttonList.map((item, index) => {
                  // if (item.id === 'assignRoom' && selectedReservation?.IdRoom) {
                  //   return (<></>)
                  // }
                  return (
                    <Fragment key={index}>
                      <Button
                        variant={item.variant}
                        // size="lg"
                        className={item.css ?? ""}
                        onClick={item.onClick}
                        disabled={
                          (props.actionMode === "I" && item.id) ||
                          (item.id === "assignRoom" &&
                            props.actionMode === "VIEW")
                        }
                      >
                        <i className={item.icon} /> {item.name}
                      </Button>
                    </Fragment>
                  );
                })}
              </div>
            </Col>
            <Col className="text-end">
              <button
                className="btn btn-primary "
                onClick={async () => {
                  handleSave();
                }}
                disabled={
                  formik.isSubmitting || isLoading || actionMode === "VIEW"
                }
              >
                <i className="fa-regular fa-floppy-disk me-2"></i>
                {t("Save")}
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReservationDetails;
