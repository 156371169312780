import axios from "axios";


const DEBUG = false;
const DebugAPIPath = "https://10.10.30.88/";



export const Settings = {
  APIPath:

    window.location.href.indexOf("eas.signos-framework.com") > -1 ?
      "https://api.eas.signos-framework.com/" :
      //window.location.href.indexOf(":8059") > -1 || 
      ((window.location.href.indexOf("10.10.30.88")) > -1 ||
        window.location.href.indexOf("lhvr_test_01") > -1)
        ? DebugAPIPath
        : window.location.href.indexOf("beta.signumpms.signos-framework.com") > -1
          ? "https://lifestylepmsapibeta.valegos.com/"
          : window.location.href.indexOf("qa.signumpms.signos-framework.com") > -1
            ? "https://lifestylepmsapiqa.valegos.com/"
            : "https://lifestylepmsapi.valegos.com/",
};
const options = {
  headers: {
    "Content-Type": "application/json",
    accept: "text/plain",
    "Access-Control-Allow-Origin": "*",
    //   httpsAgent: agent,
  },
};

export const AxiosInstance = axios.create({
  baseURL: Settings.APIPath,
  ...options,
});

const API = {
  postAction: async (endpoint, params, newOpts) =>
    AxiosInstance.post(
      endpoint,
      params !== null ? params : null,
      newOpts && newOpts
    ),
  getAction: async (endpoint, params, newOpts) =>
    AxiosInstance.get(
      endpoint + (params !== null ? "?" + params : ""),
      newOpts && newOpts
    ),
  postActionExternal: async (endpoint, params, newOpts) =>
    axios.post(
      endpoint,
      params !== null ? params : null,
      newOpts !== null ? newOpts : options
    ),
  getActionExternal: async (endpoint, newOpts) => {
    let results = axios.get(endpoint, newOpts);

    return results;
  },
};

export default API;
