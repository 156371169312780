import React from 'react';
import './home.scss';
import { Container } from 'react-bootstrap';

export default function Home() {
  return (
    <Container className='bg-red'>
      hola
    </Container>
  )
}
