import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../../api/api";
import FieldSelector from "../../customComponents/FieldSelector";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { NumberBox } from "devextreme-react";
import { useToast } from "../../../contexts/toast";

import { useTranslation } from "react-i18next";

const RatePeriodRoomTypes = (props) => {
  const { t } = useTranslation();
  const [roomTypes, setRoomTypes] = useState([]);
  const [roomTypeSelected, setRoomTypeSelected] = useState(null);
  const user = parsedUser();
  const [Allotments, setAllotments] = useState(0);
  const [Release, setRelease] = useState(0);
  const toast = useToast();
  const updateFieldRoomType = (fieldName, fieldValue) => {
    console.log(roomTypeSelected, fieldName, fieldValue);

    if (fieldName === "Allotments") {
      setAllotments(fieldValue);
      // setRoomTypeSelected({
      // 	...roomTypeSelected,
      // 	Allotments: fieldValue,
      // 	// Release: roomTypeSelected.Release
      // })
    }

    if (fieldName === "Release") {
      setRelease(fieldValue);
      // setRoomTypeSelected({
      // 	...roomTypeSelected,
      // 	Release: fieldValue,
      // 	// Allotments: roomTypeSelected.Allotments
      // })
    }
  };

  const bindRoomTypesRemote = async () => {
    try {
      if (!props.recordGUID) {
        return;
      }
      // console.log(props)
      let query = `IdUser=${user.IdUser}&token=${user.Token}&id=${props.recordGUID}`;
      var request = await API.getAction(
        "api/Contracts/RateDetailsRoomTypes",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        console.error(parsedResults.Msg);
        return;
      }

      let recordResult = parsedResults.JSONData;
      // console.log({RoomTypesRemote: recordResult});

      setRoomTypes(recordResult);

      //	}
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const bindDataRoomTypeOccupationDetailsRemote = async (item) => {
    //First load records
    // console.log(item);

    if (!item) {
      return;
    }
    let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDContractsToRatePeriod=${props.recordGUID}&GUIDRoomTypes=${item.GUIDRoomTypes}`;
    let request = await API.getAction(
      "api/Contracts/RateDetailsOccupationTypes",
      query
    );
    let results = await request.data[0];

    let parsedResults = JSON.parse(results.JSONData);
    // console.log(parsedResults)
    if (parsedResults.Error) {
      alert(parsedResults.Msg);
      return;
    }

    let finalResult = await parsedResults[0].JSONData;
    // console.log(parsedResults, finalResult)
    setOccupationTypesRecords(finalResult);

    return finalResult;
  };

  useEffect(() => {
    if (roomTypeSelected) {
      bindDataRoomTypeOccupationDetailsRemote(roomTypeSelected);
      setAllotments(roomTypeSelected.Allotments);
      setRelease(roomTypeSelected.Release);
    }
  }, [roomTypeSelected]);
  const saveAllomentAndRelease = async () => {
    if (roomTypeSelected) {
      let objToSend = `@GUID='${roomTypeSelected.GUIDContractsToRatePeriodRoomType}', @Allotments=${Allotments}, @Release=${Release}`;
      let queryData = {
        Data: objToSend,
      };
      let query = `?IdUser=${user.IdUser}&token=${user.Token}&ActionMode=U`;
      API.postAction(
        "api/Contracts/RateDetailsRoomTypesEdit" + query,
        queryData
      );

      toast({
        type: "success",
        message: t("Record saved"),
      });

      bindRoomTypesRemote();
    }
  };
  const updateSelectedOcupation = async (ocupation, rate, apply) => {
    console.log(ocupation, roomTypeSelected);
    let objToSend = `@Rate=${rate}, @Apply=${apply},@GUID='${ocupation.GUIDContractsToRatePeriodRoomTypeOfOcupations}', @IdOccupationType=${ocupation.IdOccupationType}, @IdGuestType=${ocupation.IdGuestType}, @GUIDContractsToRatePeriod='${props.recordGUID}', @IdRoomType=${roomTypeSelected.IdRoomType}`;

    let queryData = {
      Data: objToSend,
    };
    let query = `?IdUser=${user.IdUser}&token=${user.Token}&ActionMode=U`;
    var request = await API.postAction(
      "api/Contracts/RateDetailsOccupationTypesEdit" + query,
      queryData
    );

    ocupation.Rate = rate * 1;
    ocupation.Apply = apply;

    let ocupationsr = occupationTypesRecords;
    ocupationsr.filter(
      (x) =>
        x.GUIDContractsToRatePeriodRoomTypeOfOcupations ===
        ocupation.GUIDContractsToRatePeriodRoomTypeOfOcupations
    )[0] = ocupation;
    console.log(ocupationsr);
    setOccupationTypesRecords(ocupationsr);
  };
  const [occupationTypesRecords, setOccupationTypesRecords] = useState([]);
  useEffect(() => {
    bindRoomTypesRemote();
  }, [props.recordGUID]);

  return (
    <div className="bg-white py-4 px-2 border">
      <Row className="mx-0 border">
        <Col xs={3} className="bg-light">
          <b className="my-2">Room Type</b>
          <hr />
          <ul className="list-group px-2">
            {roomTypes?.length > 0 &&
              roomTypes.map((item, index) => {
                // console.log("items", item);
                return (
                  <li
                    key={`${index} - ${item.Code}`}
                    className={`list-group-item list-group-item-action d-flex  clickable justify-content-between align-items-center ${roomTypeSelected?.Code === item?.Code ? "active" : ""
                      } ${roomTypeSelected?.Code === item?.Code && "disabled"} `}
                    onClick={() => {
                      console.log(item);
                      setRoomTypeSelected(item);
                    }}
                  >
                    {item.Description}
                  </li>
                );
              })}
          </ul>
        </Col>
        <Col>
          <div className="table-responsive ">
            {roomTypeSelected ? (
              <div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>{t("Guest Type")}</th>
                      <th>{t("Occupation Type")}</th>
                      <th>{t("Rate")}</th>
                      <th>{t("Apply")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {occupationTypesRecords &&
                      occupationTypesRecords.map((item, index) => {
                        var record = item;
                        return (
                          <CustomTableRow
                            key={
                              record.GUIDContractsToRatePeriodRoomTypeOfOcupations
                            }
                            GuestType={record.GuestType}
                            OccupationType={record.OccupationType}
                            record={record}
                            callback={(rate, apply) =>
                              updateSelectedOcupation(record, rate, apply)
                            }
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Row className="mx-0">
                  {/* <Col>
                    <label className="me-2 mt-2">{t("Allotments")}</label>
                   
                    <br />

                    <NumberBox
                      showSpinButtons={true}
                      format={"#0.##"}
                      id={"Allotments"}
                      name={"Allotments"}
                      value={Allotments}
                      onValueChanged={(e) => {
                        updateFieldRoomType("Allotments", e.value);
                      }}
                      min={0}
                    />
                  </Col>
                  <Col>
                    <label className="me-2 mt-2">{t("Release")}</label>
                    
                    <br />

                    <NumberBox
                      showSpinButtons={true}
                      format={"#0.##"}
                      id={"Release"}
                      name={"Release"}
                      value={Release}
                      onValueChanged={(e) => {
                        updateFieldRoomType("Release", e.value);
                      }}
                      min={0}
                    />
                   
                  </Col> */}
                  <Col xs={12} className="mt-2 text-end">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        saveAllomentAndRelease();
                      }}
                    >
                      {t("Save")}
                    </button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <p className="h4 text-center text-muted">
                  {t("Please select a Room Type")}
                </p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RatePeriodRoomTypes;

const CustomTableRow = ({ GuestType, OccupationType, callback, record }) => {
  const [rate, setRate] = useState(0);
  const [apply, setApply] = useState(false);

  useEffect(() => {
    setRate(record?.Rate || 0);
    setApply(record?.Apply || false);
  }, []);

  const handleBlur = () => {
    callback(+rate, apply);
  };


  const handleFocus = (e) => {
    e.target.select()
  }

  return (
    <tr>
      <td>{GuestType}</td>
      <td>{OccupationType}</td>
      <td>
        <input
          type="number"
          className="form-control"
          name="Rate"
          min="0"
          step="0.00"
          onChange={(e) => {
            setRate(e.target.value);
            if (e.target.value === "" || e.target.value <= 0) {
              setApply(false);
            } else {
              setApply(true);
            }
          }}
          value={rate}
          onKeyUp={handleBlur}
          onFocus={handleFocus}
        />
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            checked={apply}
            onChange={(e) => {
              setApply(e.target.checked);
              if (!e.target.checked) {
                setRate(0);
              }
            }}
            name="Apply"
            onKeyUp={handleBlur}
          />
        </div>
      </td>
    </tr>
  );
};
