import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useToast } from "../../contexts/toast";
import API from "../../api/api";
import React, { useEffect, useState } from "react";

const AssignButtonContainer = () => {
  const user = parsedUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonList, setButtonList] = useState([
    { id: "btnAdd", name: "Add Button", checked: false },
    { id: "btnEdit", name: "Edit Button", checked: false },
    { id: "btnSearch", name: "Search Button", checked: false },
    { id: "btnDelete", name: "Delete Button", checked: false },
    { id: "btnView", name: "View Button", checked: false },
    { id: "btnFavorite", name: "Add to Favorites", checked: false },
    { id: "btnExcel", name: "Export to excel", checked: false },
    { id: "btnPdf", name: "Export to PDF", checked: false },
    { id: "btnDuplicate", name: "Duplicate", checked: false },
  ]);

  const [sectionList, setSectionList] = useState([
    { IdAppMenu: 1, name: "Loading...", checked: false },
  ]);

  const handleButtonCheck = (id) => {
    const newButton = buttonList.map((btn) => {
      if (btn.id === id) {
        btn.checked = !btn.checked;
      }
      return btn;
    });
    setButtonList(newButton);
  };

  const handleSectionCheck = (IdAppMenu) => {
    const newSection = sectionList.map((section) => {
      if (section.IdAppMenu === IdAppMenu) {
        section.checked = !section.checked;
      }
      return section;
    });
    setSectionList(newSection);
  };

  const cleanOptions = async (IdAppMenu) => {
    await API.getAction(
      "/api/Roles/MenuPermissionsDelete",
      `IdUser=${user.IdUser}&token=${user.Token}&IdAppMenu=${IdAppMenu}`
    );
    toast({
      type: "success",
      message: "Operation sent",
    });
  };
  const SectionMappers = (resp) => {
    const {
      OptionMenuLevel1,
      OptionMenuLevel2,
      OptionMenuLevel3,
      IdUserRole,
      IdAppMenu,
    } = resp;

    let fullName = "";
    if (OptionMenuLevel1 !== null) {
      fullName += OptionMenuLevel1 + " > ";
    }
    if (OptionMenuLevel2 !== null) {
      fullName += OptionMenuLevel2 + " > ";
    }
    if (OptionMenuLevel3 !== null) {
      fullName += OptionMenuLevel3;
    }
    return {
      // GUID: GUID,
      // Id: Id,
      name: fullName,
      checked: false,
      IdUserRole: IdUserRole,
      IdAppMenu: IdAppMenu,
    };
  };

  const bindRemoteData = async () => {
    setIsLoading(true);

    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDUserRole=3AE834A6-24F5-4E14-B0D5-979D3D9BBE7E`;
      const request = await API.getAction(
        "/api/Roles/CRUDcoreUserRoleDetails?",
        query
      );
      const results = request.data[0];
      const parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      const sectionResult = parsedResults.map((resp) => SectionMappers(resp));
      setSectionList(sectionResult);
    } catch (error) {
      console.log(error);
      toast({
        type: "error",
        message: "Failed to fetch data",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async () => {
    const buttonListChecked = buttonList
      .filter((btn) => btn.checked)
      .map((btn) => {
        return {
          Id: btn.id,
          Name: btn.name,
        };
      });

    const sectionListChecked = sectionList
      .filter((section) => section.checked)
      .map((section) => {
        return {
          IdAppMenu: section.IdAppMenu,
          IdUserRole: section.IdUserRole,
          Name: section.name,
        };
      });

    const JSONObject = {
      Buttons: buttonListChecked,
      Sections: sectionListChecked,
    };
    console.log(JSONObject);
    // console.log(JSON.stringify(JSONObject, null, 2));

    // Call API here

    setIsLoading(true);

    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const request = await API.postAction(
        `/api/Roles/assignButtons?${query}`,
        JSONObject
      );
      // const results = request.data[0];
      // const parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      // console.log(parsedResults);
      toast({
        type: "success",
        message: "Successfully assigned buttons",
      });
      return;
    } catch (error) {
      console.log(error);
      // toast({
      //   type: "error",
      //   message: "Failed to assign buttons",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bindRemoteData();
  }, []);

  return (
    <Container>
      <Breadcrumbs pathTitle={"Administrator / Security / Assign Buttons"} />
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2 gap-2">
            <div className="d-flex align-items-center my-2">
              <i className={"fa fa-user-lock me-3 fa-2x"}></i>
              <h5 className={"my-1"}>Assign Buttons</h5>
            </div>

            <Card bg="light" className="w-100">
              <Card.Body className="mx-2">
                <Row>
                  <Col>
                    <h5 className="fw-bold">Buttons</h5>
                    <hr />
                    <div
                      style={{ maxHeight: "500px", overflowY: "auto" }}
                      className="rounded-2"
                    >
                      <ListGroup>
                        {buttonList.map((btn) => {
                          return (
                            <ListGroup.Item key={btn.id}>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                checked={btn.checked}
                                onChange={() => handleButtonCheck(btn.id)}
                                disabled={isLoading}
                              />
                              {btn.name}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  </Col>
                  <Col>
                    <h5 className="fw-bold">Sections</h5>
                    <hr />
                    <div
                      style={{ maxHeight: "500px", overflowY: "auto" }}
                      className="rounded-2"
                    >
                      <ListGroup>
                        {sectionList.map((section) => {
                          return (
                            <ListGroup.Item key={section.IdAppMenu}>
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={section.checked}
                                onChange={() =>
                                  handleSectionCheck(section.IdAppMenu)
                                }
                                disabled={isLoading}
                              />
                              {section.name}
                              <button
                                className="btn btn-outline-primary btn-sm float-end"
                                onClick={() => cleanOptions(section.IdAppMenu)}
                              >
                                Clean options
                              </button>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="bg-light">
                <div className="d-flex justify-content-end my-2">
                  <button
                    className="btn btn-primary"
                    onClick={submitHandler}
                    disabled={isLoading}
                  >
                    Apply
                  </button>
                </div>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AssignButtonContainer;
