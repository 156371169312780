import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useAuth } from "../../contexts/auth";
import { parsedUser } from "../../utils/GetCurrentUser";
import LogFilesContainer from "../LogFiles/LogFilesContainer";
import "./Footer.scss";

import { useTranslation } from "react-i18next";
import { dateFormater } from "../../utils/date-formater";
import { USDollar } from "../../utils/number-to-currency";

export default function Footer() {
  const { t } = useTranslation();

  const { user } = useAuth();
  const userLogged = parsedUser();
  const [showLogs, setShowLogs] = useState(false);
  const checkAudit = localStorage.getItem("AuditInformation");

  // useEffect(() => {
  //   console.log('USD Rate Daily Operation:', user?.RateUSDDailyOperation);
  //   console.log('USD Rate Official Sale:', user?.RateUSDOfficialSaleRate);
  // }, [user])

  const footerItems = [
    {
      icon: <i className="fa-solid fa-user"></i>,
      key: "Username",
    },
    {
      icon: <i className="fa-solid fa-lock"></i>,
      key: "Token",
      format: (token) => token.replace(/(...)(?=.)/gm, "$1-"),
    },
  ];

  const handleChange = async (e) => {
    localStorage.setItem("AuditInformation", e.target.checked);
  };

  const handleCloseLogs = () => setShowLogs(!showLogs);

  const validateTestButton = () => {
    let routes = [
      "dev.lifestyle",
      "localhost",
      "dev.signumpms.signos-framework.com",
    ];
    let status = routes.filter((item) =>
      window.location.toString().includes(item)
    );
    return status.length > 0;
  };

  return (
    <>
      {showLogs ? (
        <LogFilesContainer show={showLogs} handleClose={handleCloseLogs} />
      ) : null}
      <footer className={"footer "}>
        <div className="footer-item">
          <div className="footer-item-icon">
            <i className="fa fa-bank me-2"></i>{t('USD Selling Rate')}:
          </div>
          <div>
            <span>{USDollar(userLogged?.RateUSDOfficialSaleRate || 0)}</span>
            {/* <Badge bg="primary fw-bold">{USDollar(userLogged?.RateUSDOfficialSaleRate)}</Badge> */}
          </div>
        </div>

        <form>
          <div className="form-check form-switch footer-item">
            <input
              className="form-check-input"
              onChange={handleChange}
              defaultChecked={checkAudit === "true" ? true : false}
              type="checkbox"
              id="CheckDefault3"
              name="AuditInformation"
            />
            <label className="form-check-label" htmlFor="CheckDefault3">
              {t("Audit Information")}
            </label>
          </div>
        </form>

        <div className="footer-item">
          <div className="footer-item-icon">
            <i className="fa fa-calendar me-2"></i> Audit date:
          </div>
          <div>
            <Badge bg="secondary">{dateFormater(userLogged?.OperationDate)}</Badge>
          </div>
        </div>

        <div className="footer-item">
          <div className="footer-item-icon">
            <i className="fa fa-database"></i>
          </div>
          <div className="footer-item-text">{user?.Database}</div>
        </div>

        {user &&
          footerItems.map(({ icon, key, format }) => (
            <div key={key} className="footer-item">
              <div className="footer-item-icon">{icon}</div>
              <div className="footer-item-text">
                {format ? format(user[key]) : user[key]}
              </div>
            </div>
          ))}
        {
          //validateTestButton()
          true ? (
            <div className="footer-item">
              <div className="footer-item-text">
                <Button
                  className="px-0"
                  variant="link"
                  size="sm"
                  onClick={() => handleCloseLogs()}
                >
                  {t("Dev Tools")}
                </Button>
              </div>
            </div>
          ) : null}
      </footer>
    </>
  );
}
