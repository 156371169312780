import React, { useCallback, useEffect, useRef, useState } from "react";
import TextBox from "devextreme-react/text-box";
import ColorBox, { ColorBoxTypes } from "devextreme-react/color-box";
import {
  CheckBox,
  DateBox,
  NumberBox,
  RadioGroup,
  SelectBox,
  TextArea,
} from "devextreme-react";
import moment from "moment/moment";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

import { useTranslation } from "react-i18next";

const FieldSelector = (props) => {
  const { t } = useTranslation();

  const model = props.model;
  const user = parsedUser();
  const [items, setItems] = useState([]);

  //REQUEST RECORDS
  const requestRecords = async (id) => {
    try {
      let query = `token=${user.Token}&id=${id}`;
      //Check for dependable values
      if (model.dependOf) {
        if (id === undefined) {
          return;
        }

        let va = props.obj && props.obj[model.dependOf];
        // console.log(va);
        if (!va) {
          return;
        }
        if (
          model.dependOf === "IdCustomer" &&
          model.FieldName === "IdCurrency"
        ) {
          query += `&column6=${model.dependOf}&value6=${va}`;
        } else {
          query += `&column1=${model.dependOf}&value1=${va}`;
        }

        // console.log(va, query)
      }
      // console.log(query);
      var request = await API.getAction("api/selector", query);
      // if (model.FieldName === 'IdRateCategoryType') {
      //   debugger;
      // }
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        console.error(parsedResults.Msg);
        return;
      }
      let recordResult = JSON.parse(parsedResults.JSONData);
      if (recordResult) {
        let parsedItems = recordResult.map((item) => ({
          id: item.Id,
          text: item.Description,
        }));

        setItems(parsedItems);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //  console.log("object change", props.obj)
    if (model.HtmlType === "select" && !props.IsReadOnly) {
      if (model.dependOf) {
        if (model.DataSource !== "NULL") {
          let dataSource = JSON.parse(model.DataSource);

          // console.log(model, props.obj);
          requestRecords(dataSource.HashID);
        } else {
          // console.log(model, model.DataSource);
        }
      }
    }
  }, [props.obj]);
  useEffect(() => {
    // console.log(props.value)
    if (!props.model) {
      return;
    }
    if (model.DataSource) {
      // console.log(model, model.DataSource);
      if (model.DataSource !== "NULL") {
        let dataSource = JSON.parse(model.DataSource);
        if (props.value) {
          requestRecords(dataSource.HashID);
        }
      }
    }
  }, [props.value]);

  useEffect(() => {
    //console.log(props.value)
    if (!props.model) {
      return;
    }
    if (model.DataSource) {
      // console.log(model, model.DataSource);
      if (model.DataSource !== "NULL") {
        let dataSource = JSON.parse(model.DataSource);
        if (props.value) {
          requestRecords(dataSource.HashID);
        }
      } else {
        console.log(model, model.DataSource);
      }
    } else if (model.OptionValues) {
      setItems(JSON.parse(model.OptionValues));
    }
  }, []);

  /*
   HAS OPTION VALUES
  */
  useEffect(() => {
    // console.log(props.model)
    if (props.model.OptionValues) {
      setItems(JSON.parse(props.model.OptionValues));
    }
  }, [props.model.OptionValues]);

  if (!props.model) {
    return;
  }
  let defaultValue =
    props.model.DefaultValue === "0"
      ? 0
      : props.model.DefaultValue === "1"
        ? 1
        : props.model.DefaultValue === true
          ? 1
          : props.model.DefaultValue === false
            ? 0
            : props.model.DefaultValue;
  let dateValue, min, max;
  switch (model.HtmlType) {
    case "checkbox":
      // console.log("checkbox", props.value, defaultValue);
      return (
        <CheckBox
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          value={props.value ?? defaultValue}
          defaultValue={defaultValue}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );
    case "email":
    case "text":
    case "file":
      return (
        <TextBox
          // className="form-control "
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          inputAttr={{ autocomplete: "new" }}
          value={props.value ?? defaultValue}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );
    case "color":
      return (
        <ColorBox
          // className="form-control "
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          inputAttr={{ autocomplete: "new" }}
          value={props.value ?? defaultValue}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );
    case "number":
      // console.log("number value", model.FieldName, props.value);
      return (
        <NumberBox
          disabled={model?.disabled}
          showSpinButtons={true}
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          format={model.CustomFormat || "#0.##"}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          inputAttr={{ autocomplete: "new" }}
          value={props.value ?? defaultValue}
          onValueChanged={(e) => {
            // console.log(e.value, model.FieldName);
            props.updateField(model.FieldName, e.value);
          }}
          min={model.AllowNegative ? undefined : model.Min ?? 0}
        // max={model.AllowNegative ? undefined : model.Max ?? 0}
        />
      );

    case "date":
      dateValue = props.value === "Invalid date" ? "" : props.value;

      min = undefined;
      if (!props.section && model.minValueDependOf && props.obj) {
        let minValue = props.obj[model.minValueDependOf];
        min = minValue;
      }

      max = undefined;
      max = model.maxDateToday && moment().format("YYYY-MM-DD");
      if (!props.section && model.maxValueDependOf && props.obj) {
        let maxValue = props.obj[model.maxValueDependOf];
        max = maxValue;
      }
      if (props.section === "SpecialRate") {
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
        console.log(props, props.sectionReferenceContent);
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        if (props.model.minValueDependOf) {
          min = props.obj[props.model.minValueDependOf];
        }
        console.log("special rate", min);
      }
      if (props.section === "RatePeriodGeneral") {
        // console.log(props.sectionReferenceContent)
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
      }
      if (props.section === "SupplementsGeneral") {
        console.log(props.sectionReferenceContent);
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
      }

      if (model.min) {
        min = model.min;
      }
      if (model.max) {
        max = model.max;
      }

      return (
        <DateBox
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          value={dateValue && dateValue !== "Invalid date" ? dateValue : ""}
          onValueChanged={(e) => {
            let format = moment(e.value).format("YYYY-MM-DD");
            props.updateField(model.FieldName, format);
          }}
          min={min}
          max={max}
        />
      );

    case "datetime-local":
      dateValue = props.value === "Invalid date" ? "" : props.value;

      min = undefined;
      if (!props.section && model.minValueDependOf && props.obj) {
        let minValue = props.obj[model.minValueDependOf];
        min = minValue;
      }

      max = undefined;
      if (!props.section && model.maxValueDependOf && props.obj) {
        let maxValue = props.obj[model.maxValueDependOf];
        max = maxValue;
      }
      if (props.section === "SpecialRate") {
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
        console.log(props, props.sectionReferenceContent);
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        if (props.model.minValueDependOf) {
          min = props.obj[props.model.minValueDependOf];
        }
        console.log("special rate", min);
      }
      if (props.section === "RatePeriodGeneral") {
        // console.log(props.sectionReferenceContent)
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
      }
      if (props.section === "SupplementsGeneral") {
        console.log(props.sectionReferenceContent);
        let minValue = props.sectionReferenceContent.StartDate;
        min = minValue;
        let maxValue = props.sectionReferenceContent.EndDate;
        max = maxValue;
      }

      // console.log(model.FieldName, model.minValueDependOf, min, max)

      return (
        <DateBox
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          value={dateValue && dateValue !== "Invalid date" ? dateValue : null}
          onValueChanged={(e) => {
            console.log("change", e);
            let format = moment(e.value).format("YYYY-MM-DD HH:mm");

            props.updateField(model.FieldName, format);
          }}
          min={min}
          max={max}
          type="datetime"
        />
      );

    case "password":
      return (
        <TextBox
          // className="form-control "
          id={model.FieldName}
          className={`${props.actionMode === "C" ||
            // props.actionMode === "U" ||
            model.ReadOnly
            ? "form-read-only"
            : ""
            }`}
          readOnly={
            props.actionMode === "C" ||
              //   props.actionMode === "U" ||
              model.ReadOnly
              ? true
              : false
          }
          mode="password"
          name={model.FieldName}
          type={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          inputAttr={{ autocomplete: "new-password" }}
          value={props.actionMode === "D" ? "" : props.value}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );

    case "textarea":
      return (
        <TextArea
          // className="form-control "
          id={model.FieldName}
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          name={model.FieldName}
          type={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          value={props.value ?? defaultValue}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );

    case "select":


      //console.log(items)
      let readOnlyConditional = false;
      if (model.ReadOnlyWhenField) {
        if (
          props.obj[model.ReadOnlyWhenField] === model.ReadOnlyWhenFieldValue
        ) {
          readOnlyConditional = true;
        }
      }

      if (props.IsReadOnly) {
        if (model.FieldName === "IdGuestType") {
          //     console.log(model.FieldName, model.FieldName?.replace("Id", ""), props.obj[model.FieldName.replace("Id", "")], props.obj)
        }
        let finalValue =
          props.obj && props.obj[model.FieldName?.replace("Id", "")];
        return (
          <TextBox
            // className="form-control "
            id={model.FieldName}
            className={`${props.actionMode === "C" ||
              props.actionMode === "U" ||
              props.actionMode === "K" ||
              model.ReadOnly
              ? "form-read-only"
              : ""
              }`}
            readOnly={
              props.actionMode === "C" ||
                props.actionMode === "U" ||
                model.ReadOnly
                ? true
                : false
            }
            name={model.FieldName}
            type={model.HtmlType}
            placeholder={t(model.PlaceHolder)}
            required={model.Required}
            value={finalValue}
            onValueChanged={(e) => {
              props.updateField(model.FieldName, e.value);
            }}
          />
        );
      }

      return (
        <SelectBox
          dataSource={items}
          displayExpr="text"
          searchEnabled={true}
          valueExpr={"id"}
          inputAttr={{ "aria-label": model.FieldName, autocomplete: "new" }}
          defaultValue={defaultValue}
          searchMode={"contains"}
          showDataBeforeSearch={true}
          searchExpr={"text"}
          searchTimeout={200}
          minSearchLength={1}
          showClearButton={true}
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={
            props.actionMode === "C" || model.ReadOnly || readOnlyConditional
              ? true
              : false
          }
          id={model.FieldName}
          onOpened={() => {
            // console.log(props, model)
            if (model.FieldName === "IdRoom") {
              // debugger;
            }
            if (!props.model) {
              return;
            }
            if (model.DataSource) {
              // console.log(model, model.DataSource);
              if (model.DataSource !== "NULL") {
                let dataSource = JSON.parse(model.DataSource);
                requestRecords(dataSource.HashID);
              } else {
                console.log(model, model.DataSource);
              }
            } else if (model.OptionValues) {
              setItems(JSON.parse(model.OptionValues));
            }
            //requestRecords();
          }}
          onSelectionChanged={(e) => {
            // console.log(e);
            if (props.onBlur) {
              props.onBlur(model.FieldName, e.selectedItem?.id);
            }
          }}
          onValueChanged={(e) => {
            props.updateField(
              model.FieldName,
              e.value,
              e.component.option("text")
            );
          }}
          name={model.FieldName}
          value={props.value ?? defaultValue}
        />
      );
    //This return the entire object instead of just the value

    case "radio":
      if (typeof defaultValue === "string") {
        defaultValue = defaultValue.replace(/['"{}]+/g, "");
      }

      let options = model.OptionValues ? JSON.parse(model.OptionValues) : null;

      // let radioValue = typeof props.value === 'string' ? props.value : props.value >= 0 ? props.value : defaultValue;

      let radioValue = props.value ?? defaultValue;
      if (radioValue === false) {
        radioValue = 0;
      }
      if (radioValue === true) {
        radioValue = 1;
      }
      const { FieldName } = model;
      // console.log({ FieldName, options, radioValue, defaultValue });

      return (
        <RadioGroup
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          // defaultValue={radioDefaultValue}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          dataSource={options}
          value={radioValue}
          layout="horizontal"
          valueExpr={"id"}
          displayExpr={"text"}
          onInitialized={() => {
            if (props.value === null || props.value === undefined)
              props.updateField(model.FieldName, radioValue);
          }}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );
    case "textCustomReadOnly":
      if (typeof defaultValue === "string") {
        defaultValue = defaultValue.replace(/['"{}]+/g, "");
      }

      let options2 = model.OptionValues ? JSON.parse(model.OptionValues) : null;

      // let radioValue = typeof props.value === 'string' ? props.value : props.value >= 0 ? props.value : defaultValue;

      let radioValue2 = props.value ?? defaultValue;
      let finalValue = options2.filter((x) => x.id === radioValue2)[0]?.text;
      console.log(radioValue2, options2, finalValue);

      return (
        <TextBox
          // className="form-control "
          className={`${props.actionMode === "C" || model.ReadOnly ? "form-read-only" : ""
            }`}
          readOnly={props.actionMode === "C" || model.ReadOnly ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={t(model.PlaceHolder)}
          required={model.Required}
          inputAttr={{ autocomplete: "new" }}
          value={finalValue ?? ""}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );

    case "separator":
      return <hr className="separatorLine" />;

    default:
      return <></>;
  }
};

export default FieldSelector;
