import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Modal, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import * as yup from "yup";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import { dateFormater } from "../../../utils/date-formater";
import { USDollar } from "../../../utils/number-to-currency";
import FieldSelector from "../../customComponents/FieldSelector";
import { paymentFields } from "./Fields/Payment.fields";
import { useTranslation } from "react-i18next";
import useCashierCustomerData from "./useCashierCustomerData";
import { CashierCustomerSelector } from "./CashierCustomerTypeSelector";
import DepositAsMethodPayment from "./DepositAsMethodPayment";
import PaymentAppliedModal from "./PaymentAppliedModal";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";

const Payment = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const toast = useToast();
  const [baseCurrency, setBaseCurrency] = useState(null);
  const [typeOfPayments, setTypeOfPayments] = useState([]);
  const [groupedTypes, setGroupedTypes] = useState({});
  const [validationsSchema, setValidationSchema] = useState(null);
  const [paymentFieldsList, setPaymentFieldsList] = useState(paymentFields);
  const [statusPartialPayment, setStatusPartialPayment] = useState(false);
  const [withDeposit, setWithDeposit] = useState(false);
  const [availableDeposit, setAvailableDeposit] = useState(0);
  const [appliedDeposit, setAppliedDeposit] = useState(0);
  const [depositBaseCurrencyData, setDepositBaseCurrencyData] = useState(null);
  const [showPaymentAppliedModal, setShowPaymentAppliedModal] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  // CustomerType: C = Customer, G = Guest, O = Other
  const { data, getCashierCustomer, isError, isLoading } =
    useCashierCustomerData("C");
  const [customerType, setCustomerType] = useState("C");
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  // For customer selector

  const handleClientType = (type) => {
    formik.setFieldValue("IdCustomer", type);
    setCustomerType(type);
    getCashierCustomer(type);
  };

  let totalAmountFolioDetails = 0;

  /**
   * * Those states are only used for the PaymentAppliedModal.jsx
   * */
  const [detailedAmounts, setDetailedAmounts] = useState({
    totalAmount: 0,
    totalAmountPaid: 0,
    changeDue: 0,
  });
  const [reservationAmount, setReservationAmount] = useState({
    totalAmount: 0,
    totalAmountPaid: 0,
    changeDue: 0,
  });
  const [GUIDBillingDocument, setGUIDBillingDocument] = useState(null);
  
  /** = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

  const bindDataBaseCurrencyRemote = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}`;

    let queryData = {
      Data: `@IdBusinessUnit=${user?.IdBusinessUnit}`,
    };
    // let prefix = idModule ? "CRUDDynamic" : "CRUD";
    let request = await API.postAction(
      `api/cashier/BaseCurrency?` + query,
      queryData ?? ""
    );
    let record = request.data[0].JSONData;
    let parsedRecord = JSON.parse(record);
    // console.log(parsedRecord);
    // debugger;
    setBaseCurrency(parsedRecord && parsedRecord[0].Records[0]);

    // console.log();
  };

  const updateSelector = (data) => {
    // console.log("🚀 ~ updateSelector ~ data:", data);
    setSelectedCustomerData(data);
    formik.setFieldValue("IdNCFType", data?.IdNCFType);
    formik.setFieldValue("RNC", data?.RNC);
    formik.setFieldValue("Name", props.generalInfo?.GuestName);
  };

  const updateField = (fieldName, fieldValue) => {
    // console.log(fieldName, fieldValue);
    // if (fieldName === 'IdNCFType') {
    //   console.log('IdNCFType', fieldValue);
    // }
    if (fieldName === "IdCustomerType") {
      handleClientType(fieldValue); // Call the function to get the cashier customer
      switch (fieldValue) {
        case "C": // Customer
          formik.setFieldValue("IdCustomerType", "C");
          paymentFields.forEach((item) => {
            if (item.FieldName === "Guest") {
              formik.setFieldValue("Guest", null);
              item.Visible = false;
              // item.ReadOnly = true;
            }
            if (item.FieldName === "IdNCFType") {
              item.Visible = true;
              item.ReadOnly = false;
            }
            if (
              item.FieldName === "IdCustomer" ||
              item.FieldName === "RNC" ||
              item.FieldName === "IdNCFType"
            ) {
              formik.setFieldValue("IdCustomer", null);
              formik.setFieldValue("RNC", null);
              formik.setFieldValue("IdNCFType", null);
              item.Visible = true;
            }
            if (item.FieldName === "Name") {
              formik.setFieldValue("Name", null);
              item.Visible = false;
            }
          });
          setPaymentFieldsList(paymentFields);
          break;
        case "G": // Guest
          formik.setFieldValue("IdCustomerType", "G");
          paymentFields.forEach((item) => {
            formik.setFieldValue("Guest", props.generalInfo.GuestName);
            if (item.FieldName === "IdNCFType" || item.FieldName === "Guest") {
              item.Visible = true;
              item.ReadOnly = item.FieldName !== "Guest" && true;
            }
            if (item.FieldName === "IdCustomer" || item.FieldName === "RNC") {
              formik.setFieldValue("IdCustomer", null);
              formik.setFieldValue("RNC", null);
              formik.setFieldValue("IdNCFType", 2);
              item.Visible = false;
            }
            if (item.FieldName === "Name") {
              formik.setFieldValue("Name", null);
              item.Visible = false;
            }
          });
          setPaymentFieldsList(paymentFields);
          break;
        case "O": // Other
          formik.setFieldValue("IdCustomerType", "O");
          paymentFields.forEach((item) => {
            if (
              item.FieldName === "IdCustomer" ||
              // item.FieldName === "Name" ||
              item.FieldName === "IdNCFType" ||
              item.FieldName === "Guest"
            ) {
              formik.setFieldValue("IdCustomer", null);
              // formik.setFieldValue("Name", null);
              formik.setFieldValue("IdNCFType", null);
              formik.setFieldValue("Guest", null);
              item.Visible = false;
            }

            if (item.FieldName === "Name" || item.FieldName === "RNC") {
              formik.setFieldValue("Name", null);
              item.Visible = true;
            }
          });
          setPaymentFieldsList(paymentFields);
          break;
        default:
          break;
      }
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  const checkCashierStatus = async () => {
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;

    let queryData = {
      Data: `@Accion='C'`,
    };
    console.log(queryData);

    let requestAPI = await API.postAction(
      "api/CashierClosing/generic?" + queryString,
      queryData
    );

    console.log("checks tatus ", requestAPI);

    if (requestAPI.status === 200) {
      const result = GetDataOfJSON(requestAPI, "data", 0, "JSONData", 0);
      const status = result.Record[0].IsCashierOpen;
      if (!status) {
        toast({
          type: "error",
          message: "Can't execute the operation if the cashier is closed",
          width: "auto",
          height: "auto",
        });
        props.toggle();
        return;
      }

      const CashierBusinessUnit = Number(
        result.Record[0].CashierOpenIdBusinessUnit
      );
      const UserBusinessUnit = Number(user.IdBusinessUnit);
      // debugger;
      if (CashierBusinessUnit !== UserBusinessUnit) {
        toast({
          type: "error",
          message:
            "You are not allowed to execute operations in this business unit. Please verify that you are working in the correct business unit and that the cashier is open",
          width: "auto",
          height: "auto",
        });
        props.toggle();
        return;
      }

      console.log(result);
    }
  };

  const fillFormikObject = async (record) => {
    await getCashierCustomer("G")
      .then((data) => {
        formik.setFieldValue("IdNCFType", data[0]?.IdNCFType);
      })
      .catch((error) => {
        console.error(error);
      });

    formik.setFieldValue("Guest", props.generalInfo.GuestName);
  };

  const bindDataPaymentTypes = async () => {
    let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservationToFolio=${props.parent.GUIDReservationToFolio}`;
    const request = await API.getAction("api/cashier/TypeOfPayments", query);
    // console.log(request);

    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    // console.log(parsedResults);
    if (parsedResults.Error) {
      confirmAlert({
        closeOnClickOutside: true,
        message: parsedResults.Msg,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      //toast({ message: parsedResults.Msg, type: 'error' });
      return;
    }

    let grouped = Object.groupBy(parsedResults.JSONData, ({ Group }) => Group);
    // console.log(grouped);
    setTypeOfPayments(parsedResults.JSONData);
    setWithDeposit(parsedResults?.JSONDataDet[0]?.withDeposit);
    if (parsedResults?.JSONDataDet[0]?.withDeposit) {
      setAvailableDeposit(parsedResults?.JSONDataDet[0]?.AvailableDeposit);
      setDepositBaseCurrencyData({
        ISOCode: parsedResults?.JSONDataDet[0]?.ISOCode,
        RateValue: parsedResults?.JSONDataDet[0]?.RateValue,
      });
    }
    // console.log("🚀 ~ bindDataPaymentTypes ~ parsedResults:", parsedResults)
    setGroupedTypes(grouped);
  };
  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (paymentFields) {
      paymentFields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleClose = () => {
    setShowPaymentAppliedModal(false);
    props.close();
  };

  const handleSubmit = async (obj, event) => {
    setIsLoadingPayment(true);
    const amountPaid = totalAmountTypePayment; // / props.generalInfo.CurrencyRate;
    const amountToBePaid = totalAmountFolioDetails; // props.generalInfo.CurrencyRate;

    let depositToBeApplied =
      appliedDeposit * depositBaseCurrencyData?.RateValue;
    // console.log({
    //   typeOfDeposit: typeof appliedDeposit,
    //   depositToBeApplied: depositToBeApplied,
    //   amountPaid: amountPaid,
    //   amountToBePaid: amountToBePaid,
    // });

    if (appliedDeposit > amountToBePaid) {
      confirmAlert({
        closeOnClickOutside: true,
        message:
          "The amount paid using the deposit cannot be greater than the total amount. Please verify.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }

    if (totalAmountTypePayment === 0 && appliedDeposit === 0) {
      confirmAlert({
        closeOnClickOutside: true,
        message: "The amount entered cannot be 0 or less than 0.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }
    // console.log(
    //   "paid ",
    //   totalAmountTypePayment,
    //   appliedDeposit,
    //   amountToBePaid
    // );
    if (
      !statusPartialPayment &&
      totalAmountTypePayment + appliedDeposit < amountToBePaid
    ) {
      confirmAlert({
        closeOnClickOutside: true,
        message: "The amount paid must be greater than the total amount.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }

    if (AmountPaidNoCash > amountToBePaid) {
      confirmAlert({
        closeOnClickOutside: true,
        message:
          "The amount paid using the selected payment method cannot be greater than the total amount to pay. Please verify.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }

    if (appliedDeposit > availableDeposit) {
      confirmAlert({
        closeOnClickOutside: true,
        message:
          "The amount paid cannot be greater than the available deposit.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }

    toast({
      type: "info",
      message: "Processing payment...",
    });

    // setShowPaymentAppliedModal(true);

    // return; // For debbuging purposes

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;
      // appliedDeposit
      // let amountPaid = totalAmountTypePayment / props.generalInfo.CurrencyRate;

      // console.log(amountPaid);

      let items = "";
      if (props.records.length > 0) {
        props.records.forEach((item) => {
          // console.log(item);
          items += `${items.length > 0 ? "," : ""}${
            item.IdReservationToFolioDetail
          }`;
        });
      }
      if (isNaN(depositToBeApplied)) {
        depositToBeApplied = 0;
      }

      let queryData = {
        Data: `@CustomerType='${obj.IdCustomerType}', @IdReservationCustomer=${
          obj.IdCustomer
        }, @Customer='${obj.Guest}', @IdNCFType=${
          obj.IdNCFType
          // }, @AmountPaid='${amountToBePaid}', @GUIDReservationToFolio='${
        }, @AmountPaid='${
          amountPaid + (depositToBeApplied ?? 0)
        }', @GUIDReservationToFolio='${
          props.parent.GUIDReservationToFolio
        }', @ListFolioDetails='${items}', @Comment='${
          obj.Comment || ""
        }', @GUIDReservation='${props.reservation.GUIDReservation}'`,
      };
      // debugger;

      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/GenerateInvoice?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        // console.log("🚀 ~ handleSubmit ~ response:", response)
        // console.log("🚀 ~ handleSubmit ~ response:", response.JSONData[0].GUIDBillingDocument)
        setGUIDBillingDocument(response.JSONData[0].GUIDBillingDocument);
        // console.log(response);
        if (response.Error) {
          toast({
            type: "error",
            message: response.Msg,
          });
          confirmAlert({
            closeOnClickOutside: true,
            message: response.Msg,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
          //setGeneralError(response.Msg);
          return;
        }

        if (appliedDeposit > 0) {
          await ApplyDeposit(response.JSONData[0].IdBillingDocument);
        }

        // NOW let's send all the payments methods with values
        typeOfPayments
          .filter((x) => x.Amount > 0)
          .forEach(async (item) => {
            let query = `IdUser=${user.IdUser}&token=${user.Token}`;
            let queryData = {
              Data: `@IdBillingDocument=${response.JSONData[0].IdBillingDocument},
            @IdPaymentType=${item.Id},
            @Amount=${item.Amount}
            `,
            };

            let requestAddPaymentMethod = await API.postAction(
              `api/cashier/AddPaymentMethod?` + query,
              queryData ?? ""
            );
            // console.log(requestAddPaymentMethod);
          });

        toast({
          type: "success",
          message: "Record saved successfully",
        });

        setShowPaymentAppliedModal(true);
        // props.close();
        // props.toggle();
      }
    } catch (error) {
      // console.log(error);
      console.error(error);
      confirmAlert({
        closeOnClickOutside: true,
        message: `${error}`,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      toast({
        type: "error",
        message: "An error occurred while completing the process",
        width: "auto",
        height: "auto",
      });
      return;
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const ApplyDeposit = async (IdBillingDocument) => {
    try {
      const query = `IdUser=${user.IdUser}&token=${user.Token}`;
      const queryData = {
        Data: `@IdBillingDocument=${IdBillingDocument}, @Amount=${appliedDeposit}, @Accion='V'`,
      };
      await API.postAction(
        `api/Cashier/ApplyPaymentMethod?` + query,
        queryData ?? ""
      );
      // console.log(requestDepositPaymentMethod);
      // toast({
      //   type: "success",
      //   message: "Deposit applied successfully",
      // });
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast({
          type: "error",
          message: error.message,
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // console.log("props", props);
    checkCashierStatus();
    prepareFormikObject();
    bindDataPaymentTypes();
    fillFormikObject();
  }, []);
  useEffect(() => {
    bindDataBaseCurrencyRemote();
  }, [props.selectedReservation]);

  const [totalAmountTypePayment, setTotalAmountTypePayment] = useState(0);
  const [AmountPaidNoCash, setAmountPaidNoCash] = useState(0);

  const updateReference = (item, val, ref) => {
    const types = typeOfPayments.map((x) => {
      if (x.Id === item.Id) {
        x[ref] = val;
      }
      return x;
    });
    setTypeOfPayments(types);
  };

  const updatePaymentMethod = (item2, val) => {
    try {
      // console.log(user);

      item2.Amount = val * 1;
      const types = typeOfPayments.map((x) => {
        if (x.Id === item2.Id) {
          x.Amount = val * 1;
        }
        return x;
      });
      // console.log(types);
      const totals = types
        .filter((x) => x.Amount > 0)
        .reduce((a, b) => {
          // console.log(a, b);

          return (
            a + calcAmountAndRate(b.Amount, b.RateValue, b, b.CurrencyCode)
          );
        }, 0);

      const totalAmountNotCash = types
        .filter((x) => x.Amount > 0 && x.IsCashPayment === false)
        .reduce(
          (a, b) =>
            a + calcAmountAndRate(b.Amount, b.RateValue, b, b.CurrencyCode),
          0
        );
      setAmountPaidNoCash(totalAmountNotCash);
      setTotalAmountTypePayment(totals);
      setTypeOfPayments(types);
      checkIfCanBePaid();
    } catch (err) {
      console.error(err);
    }
  };

  // const calcTotalAmountPaid = () => {
  //   console.log(user);
  //   const totals = typeOfPayments
  //     .filter((x) => x.Amount > 0)
  //     .reduce((a, b) => {
  //       console.log(a, b);

  //       return (
  //         a +
  //         calcAmountAndRate(
  //           b.Amount,
  //           b.CurrencyCode === "DOP"
  //             ? 1 / (user.RateUSDOfficialSaleRate * 1)
  //             : b.RateValue,
  //           b,
  //           b.CurrencyCode
  //         )
  //       );
  //     }, 0);

  //   return totals;
  // };

  const calcAmountAndRate = (amount, rate, obj, currency) => {
    /// TODO: change or verify this because there is an issue regarding how the rate is calculated
    /// Must be confirmed with Alfredo at the end of the day
    // console.log(obj);

    let rateToBeUsed = rate;
    let subTotal = amount * rateToBeUsed || 0;
    // console.log(
    //   obj.CurrencyCode,
    //   "rate",
    //   rate,
    //   "rate to be used",
    //   rateToBeUsed,

    //   subTotal
    // );
    return subTotal;
  };

  const [canBePaid, setCanBePaid] = useState(false);
  const checkIfCanBePaid = () => {
    console.log(
      "🚀 ~ useEffect ~ totalAmountTypePayment:",
      totalAmountTypePayment
    );

    const totalAmount = totalAmountTypePayment + Number(appliedDeposit);
    console.log("🚀 ~ checkIfCanBePaid ~ totalAmount:", totalAmount);
    if (totalAmount <= 0) {
      console.log("No se puede pagar");
      setCanBePaid(false);
    } else {
      console.log("Se puede pagar");
      setCanBePaid(true);
    }
  };
  useEffect(() => {
    checkIfCanBePaid();
  }, [totalAmountTypePayment, appliedDeposit]);

  // const shouldbePaid = () => {
  //   let totalAmount = totalAmountTypePayment + appliedDeposit;
  //   if (totalAmount < 0) {
  //     return false;
  //   }
  //   return true;
  // }

  useEffect(() => {
    /**
     * * Those states are only used for the PaymentAppliedModal.jsx
     * */
    setDetailedAmounts((prev) => ({
      ...prev,
      totalAmount: props.records.reduce((a, b) => a + b.OriginalAmount, 0),
      totalAmountPaid: totalAmountTypePayment + appliedDeposit,
      changeDue: totalAmountTypePayment - totalAmountFolioDetails,
    }));
    setReservationAmount((prev) => ({
      ...prev,
      totalAmount: totalAmountFolioDetails / props.generalInfo.CurrencyRate,
      // totalAmount: props.records.reduce((a, b) => a + b.OriginalAmount, 0),
      totalAmountPaid: (totalAmountTypePayment + appliedDeposit) / props.generalInfo.CurrencyRate,
      changeDue: (totalAmountTypePayment + appliedDeposit - totalAmountFolioDetails) / props.generalInfo.CurrencyRate,
      // changeDue: totalAmountTypePayment - totalAmountFolioDetails,
    }));
  }, [
    props.records,
    appliedDeposit,
    totalAmountFolioDetails,
    totalAmountTypePayment,
  ]);

  return (
    <>
      {showPaymentAppliedModal && (
        <PaymentAppliedModal
          show={showPaymentAppliedModal}
          close={() => setShowPaymentAppliedModal(false)}
          handleClose={handleClose}
          totalAmountFolioDetails={totalAmountFolioDetails}
          totalAmountTypePayment={totalAmountTypePayment}
          appliedDeposit={appliedDeposit}
          baseCurrency={baseCurrency}
          detailedAmounts={detailedAmounts}
          reservationAmount={reservationAmount}
          selectedReservation={props.selectedReservation}
          GUIDBillingDocument={GUIDBillingDocument}
          generalInfo={props.generalInfo}
        />
      )}
      <Modal
        show={props.show}
        onHide={props.close}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modalRight z-3"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Payment")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid className="overflow-auto table-responsive">
            <Row className="border pb-4 mb-4">
              {paymentFieldsList &&
                paymentFieldsList
                  .filter((x) => x.GroupBy === 0)
                  .map((item, index) => {
                    if (item.FieldName === "IdCustomer") {
                      return (
                        <CashierCustomerSelector
                          records={data}
                          customerType={customerType}
                          selectedCustomerData={updateSelector}
                          isLoading={isLoading}
                        />
                      );
                    }
                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={`col-${item.FieldName}-${index}`}
                      >
                        <label className="me-2 mt-2">
                          {item.FieldTitle}
                          {item.Required ? (
                            <i className="required-asterisk ms-1 text-danger">
                              *
                            </i>
                          ) : null}
                        </label>
                        <br />

                        <FieldSelector
                          actionMode={""}
                          model={item}
                          obj={formik.values}
                          key={`field-${item.FieldName}`}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm my-1">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
            </Row>
            <Row>
              {/* Left coulumn */}
              <Col xs={12} md={6}>
                <Row></Row>
              </Col>
              {/* RIGHT COLUMN */}
              <Col xs={12} md={6}>
                <Row>
                  {paymentFieldsList &&
                    paymentFieldsList
                      .filter((x) => x.GroupBy === 1)
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={`col-${item.FieldName}-${index}`}
                          >
                            <label className="me-2 mt-2">
                              {item.FieldTitle}
                              {item.Required ? (
                                <i className="required-asterisk ms-1 text-danger">
                                  *
                                </i>
                              ) : null}
                            </label>
                            <br />

                            <FieldSelector
                              actionMode={""}
                              model={item}
                              obj={formik.values}
                              key={`field-${item.FieldName}`}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            ></FieldSelector>
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm my-1">
                                {formik.errors[item.FieldName]}
                              </div>
                            ) : null}
                          </Col>
                        );
                      })}
                </Row>
              </Col>
              {/* SELECTED EXPENSES */}
              <Col xs={12} md={12} lg={6} className="mt-3">
                <h5 className="mb-2">Expenses</h5>
                <div className="table-responsive" style={{ height: "250px" }}>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Date</th>
                        <th className="text-nowrap">Billing Services</th>
                        <th className="text-nowrap">References</th>
                        <th className="text-nowrap">
                          Amount {props.generalInfo.Currency}
                        </th>
                        <th className="text-nowrap">Amount DOP</th>
                        <th className="text-nowrap">Billing Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.records &&
                        props.records.length > 0 &&
                        props.records.map((item, index) => {
                          totalAmountFolioDetails += item.OriginalAmount * 1;
                          // console.log(item);

                          return (
                            <tr key={index}>
                              <td>{dateFormater(item.TransactionDate)}</td>
                              <td>{item.BillingService}</td>
                              <td>{item.Reference}</td>
                              <td>
                                {USDollar(
                                  item.OriginalAmount /
                                    props.generalInfo.CurrencyRate
                                )}
                              </td>
                              <td>{USDollar(item.OriginalAmount)}</td>

                              <td>
                                <span
                                  className="badge w-100"
                                  style={{
                                    background: item.BackgroundColorStatus,
                                    color: item.TextColorStatus,
                                  }}
                                >
                                  {item.Status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={6}>
                          <span>
                            Grant Total:{" "}
                            {USDollar(
                              totalAmountFolioDetails /
                                user?.RateUSDOfficialSaleRate
                            )}
                            {/* {totalAmountFolioDetails.toFixed(2)} */}
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <Row>
                  {paymentFieldsList &&
                    paymentFieldsList
                      .filter((x) => x.GroupBy === 2)
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={`col-${item.FieldName}-${index}`}
                          >
                            <label className="me-2 mt-2">
                              {item.FieldTitle}
                              {item.Required ? (
                                <i className="required-asterisk ms-1 text-danger">
                                  *
                                </i>
                              ) : null}
                            </label>
                            <br />

                            <FieldSelector
                              actionMode={""}
                              model={item}
                              obj={formik.values}
                              key={`field-${item.FieldName}`}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            ></FieldSelector>
                            {formik.errors[item.FieldName] ? (
                              <div className="invalid text-sm my-1">
                                {formik.errors[item.FieldName]}
                              </div>
                            ) : null}
                          </Col>
                        );
                      })}
                </Row>
                <Row>
                  {/* RESERVATION RATE */}
                  <Col xs={12} md={6}>
                    <div>
                      {/* {calcTotalAmountPaid()} */}
                      <label className="me-2 mt-2">
                        Total Amount {props.generalInfo.Currency}
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control fw-bolder text-xl"
                        value={USDollar(
                          totalAmountFolioDetails /
                            props.generalInfo.CurrencyRate
                        )}
                        // value={totalAmountFolioDetails.toFixed(2)}
                        style={{ color: "GrayText" }}
                        disabled
                      ></input>
                    </div>
                    <div>
                      {/* Here is the total amount paid on the payment methods. */}
                      <label className="me-2 mt-2">
                        Total Amount Paid {props.generalInfo.Currency}
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control fw-bolder"
                        value={USDollar(
                          (totalAmountTypePayment + appliedDeposit) /
                            props.generalInfo.CurrencyRate
                        )}
                        style={{ color: "GrayText" }}
                        disabled
                      ></input>
                    </div>
                    <div>
                      <label className="me-2 mt-2">
                        Change Due {props.generalInfo.Currency}
                      </label>
                      <br />
                      <input
                        type="text"
                        className={`${
                          totalAmountTypePayment +
                            appliedDeposit -
                            totalAmountFolioDetails <
                          0
                            ? " text-danger"
                            : ""
                        } form-control fw-bolder`}
                        value={
                          totalAmountTypePayment + appliedDeposit > 0
                            ? USDollar(
                                (totalAmountTypePayment +
                                  appliedDeposit -
                                  totalAmountFolioDetails) /
                                  props.generalInfo.CurrencyRate
                              )
                            : USDollar(0)
                        }
                        style={{ color: "GrayText" }}
                        disabled
                      ></input>
                    </div>
                  </Col>
                  {/* BASE CURRENCY */}
                  <Col xs={12} md={6} className="mb-3">
                    <div>
                      <label className="me-2 mt-2">
                        Total Amount {baseCurrency && baseCurrency.Currency}
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control fw-bolder text-xl"
                        value={USDollar(totalAmountFolioDetails)}
                        disabled
                      ></input>
                    </div>
                    <div>
                      {/* Here is the total amount paid on the payment methods. */}
                      <label className="me-2 mt-2">
                        Total Amount Paid{" "}
                        {baseCurrency && baseCurrency.Currency}
                      </label>
                      <br />
                      <input
                        type="text"
                        className="form-control fw-bolder"
                        value={USDollar(
                          totalAmountTypePayment + appliedDeposit
                        )}
                        disabled
                      ></input>
                    </div>
                    <div>
                      <label className="me-2 mt-2">
                        Change Due {baseCurrency && baseCurrency.Currency}
                      </label>
                      <br />

                      <input
                        type="text"
                        className={`${
                          totalAmountTypePayment - totalAmountFolioDetails < 0
                            ? " text-danger"
                            : ""
                        } form-control fw-bolder`}
                        value={
                          totalAmountTypePayment + appliedDeposit > 0
                            ? USDollar(
                                totalAmountTypePayment +
                                  appliedDeposit -
                                  totalAmountFolioDetails
                                //props.generalInfo.CurrencyRate
                              )
                            : USDollar(0)
                        }
                        disabled
                      ></input>
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-center mt-0">
                    {statusPartialPayment &&
                      totalAmountTypePayment <
                        totalAmountFolioDetails *
                          props.generalInfo.CurrencyRate && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3 rounded shadow-sm w-100"
                          style={{
                            backgroundColor: "#FDF4CD",
                            color: "#664907",
                          }}
                        >
                          <span>
                            <i className="fa-solid fa-triangle-exclamation" />{" "}
                            {t("You're going to make a partial payment!")}
                          </span>
                        </div>
                      )}
                  </Col>
                </Row>
              </Col>

              {/* PAYMENT METHODS */}
              <Col xs={12} md={12} lg={6} className="mt-3">
                {withDeposit === 1 && (
                  <Col xs={12} className="d-flex justify-content-center my-3">
                    <div className="d-flex justify-content-center align-items-center p-3 rounded shadow-sm w-100 alert alert-success">
                      <span>
                        <i className="fa-solid fa-triangle-exclamation me-2" />{" "}
                        {t("Customer has a deposit available!")}
                      </span>
                    </div>
                  </Col>
                )}
                {withDeposit === 1 && (
                  <DepositAsMethodPayment
                    DepositAvailable={availableDeposit}
                    setAppliedDeposit={(val) => {
                      setAppliedDeposit(val);
                    }}
                    depositBaseCurrency={depositBaseCurrencyData}
                    baseCurrency={baseCurrency}
                  />
                )}
                <h5>Payment Methods</h5>
                <Accordion defaultActiveKey={0}>
                  {Object.keys(groupedTypes).length > 0 &&
                    Object.keys(groupedTypes).map((item, index) => {
                      // console.log(item);
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header className="text-nowrap">
                            {item}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={12} className="table-responsive">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap">Concept</th>
                                      <th className="text-nowrap">Amount</th>
                                      <th className="text-nowrap">
                                        Amount{" "}
                                        {baseCurrency && baseCurrency.Currency}
                                      </th>
                                      <th className="text-nowrap">
                                        Reference 1
                                      </th>
                                      <th className="text-nowrap">
                                        Reference 2
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {typeOfPayments
                                      .filter((x) => x.Group === item)
                                      .map((item2, index2) => {
                                        // totalAmountTypeOfPayments +=
                                        //   item2.Amount * 1;
                                        return (
                                          <tr key={index}>
                                            <td className="text-no-wrap">
                                              {item2.Description}
                                              <br />
                                              <span className="text-muted">
                                                1 {item2.CurrencyCode} ={" "}
                                                {USDollar(item2.RateValue)}
                                              </span>
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                className="form-control"
                                                value={item2.Amount}
                                                onChange={(e) => {
                                                  updatePaymentMethod(
                                                    item2,
                                                    e.target.value
                                                  );
                                                  //now proceed with saving the record in the array
                                                }}
                                              ></input>
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={USDollar(
                                                  calcAmountAndRate(
                                                    item2.Amount,
                                                    item2.CurrencyCode === "DOP"
                                                      ? 1
                                                      : item2.RateValue,
                                                    item2
                                                  )
                                                )}
                                                disabled
                                              ></input>
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => {
                                                  updateReference(
                                                    item2,
                                                    e.target.value,
                                                    "reference1"
                                                  );
                                                }}
                                                value={item.reference1}
                                              ></input>
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => {
                                                  updateReference(
                                                    item2,
                                                    e.target.value,
                                                    "reference2"
                                                  );
                                                }}
                                                value={item.reference2}
                                              ></input>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100 align-items-center">
            <Col xs={6}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="partialPayment"
                  id="flexSwitchCheckDefault"
                  onChange={(e) => {
                    setStatusPartialPayment(e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  {t("Partial Payment")}
                </label>
              </div>
            </Col>
            <Col xs={6}>
              <div className="d-flex gx-3 justify-content-end">
                {/* <span>{AmountPaidNoCash}</span> */}
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}

                <button
                  className="btn me-2"
                  type="button"
                  onClick={props.toggle}
                >
                  Close
                </button>

                <button
                  className="btn btn-primary me-2"
                  disabled={!canBePaid || isLoadingPayment}
                  type="button"
                  onClick={() => {
                    if (
                      totalAmountTypePayment > 0 &&
                      statusPartialPayment &&
                      totalAmountTypePayment <
                        totalAmountFolioDetails * props.generalInfo.CurrencyRate
                    ) {
                      confirmAlert({
                        message: t("Are you sure? This is a partial payment."),
                        buttons: [
                          {
                            label: t("Yes"),
                            onClick: async () => {
                              formik.submitForm();
                            },
                          },
                          {
                            label: t("No"),
                            onClick: async () => {
                              return;
                            },
                          },
                        ],
                      });
                    } else {
                      formik.submitForm();
                    }
                  }}
                >
                  Apply payment
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payment;
